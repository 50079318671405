<div
  class="petszel-partner-search-result-list-item mb-4 d-flex flex-column"
  *ngIf="selectedPartner"
>
  <a
    (click)="goBackToListView()"
    class="back-link mb-2"
    *ngIf="!showPetSelection"
  >
    <strong> <i class="bi bi-chevron-compact-left"></i>Back </strong>
  </a>
  <div *ngIf="!showPetSelection" class="padding-search">
    <div class="image-container">
      <span *ngIf="isPartnerFavorited()" class="heart-icon">
        <i class="bi bi-suit-heart-fill red-heart"></i>
        <span class="shelter-text">Shelter Preferred</span>
      </span>

      <img
        [src]="
          selectedPartner.photos && selectedPartner.photos.length > 0
            ? selectedPartner.photos[0].getUrl()
            : ''
        "
        alt="Partner Photo"
        class="partner-photo img-fluid"
        *ngIf="selectedPartner.photos && selectedPartner.photos.length > 0"
      />
    </div>

    <div class="row">
      <div class="col">
        <h3 class="search-result-list-item-title" *ngIf="selectedPartner.name">
          {{ selectedPartner.name }}
        </h3>

        <div *ngIf="isRatingValid()" class="stars-container d-flex">
          <p class="pe-2 rating mb-2">({{ selectedPartner.rating }})</p>
          <i
            *ngFor="let star of getStarsArray()"
            class="bi bi-star-fill mx-1"
          ></i>
        </div>
        <div *ngFor="let category of categories" class="row category-row">
          <div class="col-auto category-col">
            <!-- Wrapper div for image and category name -->
            <div class="category-wrapper">
              <img [src]="getIconForCategory(category.category)" [alt]="category.category" class="category-icon">
              <p class="m-0 p-0">{{ category.category }}</p>
            </div>
          </div>
          <div class="col-auto category-col">
            <!-- Iterate over secondary categories -->
            <div *ngFor="let secondaryCategory of category.secondaryCategories" class="category-wrapper">
              <img [src]="getIconForCategory(secondaryCategory)" [alt]="secondaryCategory" class="category-icon">
              <p class="m-0 p-0">{{ secondaryCategory }}</p>
            </div>
          </div>
        </div>      
        <div class="row" *ngIf="!areAllPetsAssociated && !hasSelectionChanged">
          <div class="col-md-4 col-6">
            <lib-primary-btn
              primaryBtn="primaryBtnSm"
              (click)="togglePetSelection()"
              buttonText="Add"
            ></lib-primary-btn>
          </div>
        </div>
      </div>
      <div class="row mt-4 p-0">
        <div class="col-6 font-location">
          <i class="bi bi-geo-alt-fill"></i>Location
        </div>
        <div class="col-6 text-end p-0">
          <a *ngIf="selectedPartner.formatted_address" [href]="getGoogleMapsUrl(selectedPartner.formatted_address)" target="_blank" class="search-result-list-item-address m-0 hours text-black">
            {{ selectedPartner.formatted_address }}
        </a>
        </div>
      </div>
      
      <div class="row mx-auto p-0">
        <div class="col d-flex justify-content-end hours">
          {{ (distanceFromChild ?? 0) * 0.621371 | number : "1.2-2" }} mi
        </div>
      </div>

      <div class="row mt-3 p-0">
        <div class="col-6 font-location">
          <i class="bi bi-telephone-fill"></i>Phone
        </div>
        <div class="col-6 text-end p-0">
          <a *ngIf="selectedPartner.formatted_phone_number" href="tel:{{ selectedPartner.formatted_phone_number }}" class="search-result-list-item-phone hours">
            {{ selectedPartner.formatted_phone_number }}
        </a>
        </div>
      </div>

      <div class="row mt-2 p-0"                       
      *ngIf="selectedPartner.website"
      >
        <div class="col-6 font-location">
          <i class="bi bi-calendar3-range"></i>Website
        </div>
        <div class="col-6 text-end p-0">
          <p class="search-result-list-item-phone hours">
            <a
              class="website"
              href="{{ selectedPartner.website }}"
              target="_blank"
              rel="noopener noreferrer"
              *ngIf="selectedPartner.website"
            >
              Website
            </a>
          </p>
        </div>
      </div>

      <div
      class="row mx-auto mt-2 m-0 p-0"
      *ngIf="selectedPartner.current_opening_hours"
    >
    <div class="col-md-5 col-5 font-location m-0 p-0">
      <i class="bi bi-clock-fill"></i>Hours
    </div>
      <div
        class="col-md-7 col-7 m-0 p-0 d-flex justify-content-end"
        (click)="toggleWeekdaySelection(selectedPartner)"
      >
        <p class="hours m-0">
          <span
            *ngIf="
              selectedPartner.current_opening_hours.open_now;
              else closedBlock
            "
          >
            <strong>Open |</strong> Closes at
            {{ getClosingTime(selectedPartner) }}
          </span>
          <ng-template #closedBlock>
            <strong>Closed |</strong> Opens at
            {{ getOpeningTime(selectedPartner) }}
          </ng-template>
          <i
            [ngClass]="
              selectedPartner.showWeekdays
                ? 'bi bi-chevron-down'
                : 'bi bi-chevron-right'
            "
          ></i>
        </p>
      </div>
    </div>

    <div class="row mx-auto mt-2 p-0" *ngIf="selectedPartner.showWeekdays">
      <div class="col-12">
        <ul
          class="list-unstyled"
          *ngIf="selectedPartner.current_opening_hours?.weekday_text"
        >
          <li
            *ngFor="
              let dayText of selectedPartner.current_opening_hours
                ?.weekday_text
            "
            class="hours-text"
          >
            <strong>{{
              dayText.slice(0, dayText.indexOf(":"))
            }}</strong
            >{{ dayText.slice(dayText.indexOf(":")) }}
          </li>
        </ul>
      </div>
    </div>
      <div #mapContainer style="height: 200px" class="mt-4"></div>
    </div>
  </div>
  <div *ngIf="showPetSelection" class="pet-selection-wrapper">
    <!-- Add the X icon in the top right corner -->
    <div class="row">
      <div class="col d-flex justify-content-end">
        <i class="fs-4 bi-x-lg close-icon" (click)="togglePetSelection()"></i>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <p class="font-size14">
          <strong>Which pet would you like to add to this provider?</strong>
        </p>
        <form (submit)="submitPetSelection()">
          <div class="h6 mt-4">
            <input
              type="radio"
              value="selectAll"
              [(ngModel)]="selectedPetId"
              (ngModelChange)="onPetSelect()"
              name="selectedPet"
              [disabled]="areAllPetsDisabled()"
              id="selectAllPets"
            />
            Select All
          </div>
          <div *ngFor="let pet of pets; trackBy: trackByPetId" class="h6 my-4">
            <input
              type="radio"
              [value]="pet.id"
              [(ngModel)]="selectedPetId"
              (ngModelChange)="onPetSelect()"
              name="selectedPet"
              [attr.disabled]="
                isPetAssociatedWithSelectedPlace(pet.id) ? true : null
              "
              id="pet_{{ pet.id }}"
            />
            <label
              class="radio-label"
              [for]="'pet_' + pet.id"
              [class.disabled]="isPetAssociatedWithSelectedPlace(pet.id)"
            >
              {{ pet.name }}
            </label>
          </div>
          <lib-primary-btn
  primaryBtn="primaryBtnSm"
  buttonText="Save Provider"
  type="submit"
  (ngModelChange)="onPetSelect()"

  [disabled]="disableButton"
></lib-primary-btn>


        </form>
      </div>
    </div>
  </div>
</div>

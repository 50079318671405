import { InjectionToken } from '@angular/core';

export function sharedLibraryConfigFactory(appConfig: any): any {
  const frontEndUrl = window.location.href;
  let apiUrl, applicationId, environmentType, gaTrackingId, gtmId;

  if (frontEndUrl.includes('localhost')) {
    environmentType = 'local';
  } else if (frontEndUrl.includes('dev')) {
    environmentType = 'development';
  } else if (frontEndUrl.includes('beta')) {
    environmentType = 'beta';
  } else {
    environmentType = 'production';
  }

  if (environmentType === 'local') {
    apiUrl = appConfig.dev.apiUrl;
    applicationId = appConfig.applicationId;
    gaTrackingId = appConfig.dev.gaTrackingId;
  } else if (environmentType === 'development') {
    apiUrl = appConfig.dev.apiUrl;
    applicationId = appConfig.applicationId;
    gaTrackingId = appConfig.dev.gaTrackingId;
  } else if (environmentType === 'beta') {
    apiUrl = appConfig.beta.apiUrl;
    applicationId = appConfig.applicationId;
    gaTrackingId = appConfig.beta.gaTrackingId;
  } else if (environmentType === 'production') {
    apiUrl = appConfig.prod.apiUrl;
    applicationId = appConfig.applicationId;
    gaTrackingId = appConfig.prod.gaTrackingId;
    gtmId = appConfig.prod.gtmId;
  }

  return {
    apiUrl,
    applicationId,
    environmentType,
    gaTrackingId 
  };
}

export const SHARED_LIBRARY_CONFIG = new InjectionToken<any>('sharedLibraryConfig');

export const sharedLibraryConfigProvider = {
  provide: SHARED_LIBRARY_CONFIG,
  useFactory: sharedLibraryConfigFactory,
  deps: ['appConfig'],
};

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit,Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';


import { filter, map, mergeMap } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'Petszel Connect';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2, 
    private router: Router, 
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route: any) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter((route: any) => route.outlet === 'primary'))
      .pipe(mergeMap((route: any) => route.data))
      .subscribe((event: any) => this.updateBodyClass(event.bodyClass));
  }

  private updateBodyClass(customBodyClass?: string) {
    this.renderer.setAttribute(this.document?.body, 'class', '');
    if (customBodyClass) {
      this.renderer.addClass(this.document?.body, customBodyClass);
    }
  }
}

<div class="row">
  <!-- Small screen: always apply 'col-12' -->
  <div class="col-12 map-div m-0 p-0" *ngIf="!isDetailView && isSmallScreen">
    <div #thirdMapContainer style="height: 200px; width: 100%"></div>
  </div>
  <div class="col-md-5 col-12 p-0 m-0 detail-view" *ngIf="!isDetailView">
    <div>
      <div *ngFor="let place of places$ | async">
        <!-- ... your list item structure ... -->
        <div class="card-google">
          <div class="card-body">
            <div class="petszel-partner-search-result-list-item">
              <div class="row">
                <div class="col">
                  <a
                    (click)="showDetails(place); selectPlace(place)"
                    style="cursor: pointer"
                  >
                    <h5 class="search-result-list-item-title">
                      <span>
                        <i class="bi bi-suit-heart-fill red-heart"></i> </span
                      >{{ place.name }}
                    </h5>
                    <div class="stars-container d-flex">
                      <div
                        *ngIf="isRatingValid(place)"
                        class="stars-container d-flex"
                      >
                        <p class="pe-2 rating">({{ place.rating }})</p>
                        <i
                          *ngFor="let star of getStarsArray(place)"
                          class="bi bi-star-fill mx-1"
                        ></i>
                      </div>
                    </div>
                    <p class="search-result-list-item-address w-100">
                      {{ place.formatted_address }}
                    </p>
                    <p class="search-result-list-item-phone">
                      {{ place.formatted_phone_number }}
                    </p>
                  </a>

                  <a
                    *ngIf="place.website"
                    href="{{ place.website }}"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Website</a
                  >
                  <div
                    class="row mx-auto mt-2 m-0 p-0"
                    *ngIf="place.current_opening_hours"
                  >
                    <div
                      class="col-12 m-0 p-0"
                      (click)="toggleWeekdaySelection(place)"
                    >
                      <p class="hours m-0">
                        <span
                          *ngIf="
                            place.current_opening_hours.open_now;
                            else closedBlock
                          "
                        >
                          <strong>Open |</strong> Closes at
                          {{ getClosingTime(place) }}
                        </span>
                        <ng-template #closedBlock>
                          <strong>Closed |</strong> Opens at
                          {{ getOpeningTime(place) }}
                        </ng-template>
                        <i
                          [ngClass]="
                            place.showWeekdays
                              ? 'bi bi-chevron-down'
                              : 'bi bi-chevron-right'
                          "
                        ></i>
                      </p>
                    </div>
                  </div>

                  <div class="row mx-auto mt-2 p-0" *ngIf="place.showWeekdays">
                    <div class="col-12">
                      <ul
                        class="list-unstyled"
                        *ngIf="place.current_opening_hours?.weekday_text"
                      >
                        <li
                          *ngFor="
                            let dayText of place.current_opening_hours
                              ?.weekday_text
                          "
                          class="hours-text"
                        >
                          <strong>{{
                            dayText.slice(0, dayText.indexOf(":"))
                          }}</strong
                          >{{ dayText.slice(dayText.indexOf(":")) }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-5 col-12 selected-view" *ngIf="isDetailView && selectedPlace">
    <div>
      <div
        class="petszel-partner-search-result-list-item-selected mb-4 d-flex flex-column"
      >
        <a class="back-link my-2" (click)="goBack()" *ngIf="!showPetSelection">
          <strong> <i class="bi bi-chevron-compact-left"></i>Back </strong>
        </a>
        <div *ngIf="!showPetSelection">
          <div class="image-container">
            <span class="heart-icon">
              <i class="bi bi-suit-heart-fill red-heart"></i>
              <span class="shelter-text">Shelter Preferred</span>
            </span>

            <img
              [src]="
                selectedPlace.photos && selectedPlace.photos.length > 0
                  ? selectedPlace.photos[0].getUrl()
                  : ''
              "
              alt="Partner Photo"
              class="partner-photo img-fluid"
              *ngIf="selectedPlace.photos && selectedPlace.photos.length > 0"
            />
          </div>

          <div class="row">
            <div class="col">
              <h3 class="search-result-list-item-title">
                {{ selectedPlace.name }}
              </h3>

              <div
                *ngIf="isRatingValid(selectedPlace)"
                class="stars-container d-flex"
              >
                <p class="pe-2 rating mb-2">({{ selectedPlace?.rating }})</p>
                <i
                  *ngFor="let star of getStarsArray(selectedPlace)"
                  class="bi bi-star-fill mx-1"
                ></i>
              </div>

              <div class="row">
                <div class="col-md-4 col-6">
                  <lib-primary-btn
                    primaryBtn="primaryBtnSm"
                    (click)="togglePetSelection()"
                    buttonText="Add"
                    *ngIf="!areAllPetsAssociated && !hasSelectionChanged"
                  ></lib-primary-btn>
                </div>
              </div>

              <div class="row m-auto mt-4 p-0">
                <div class="col-md-6 m-0 p-0 col-4 font-location">
                  <i class="bi bi-geo-alt-fill"></i>Location
                </div>
                <div class="col-md-6 m-0 p-0 col-8 text-end">
                  <p
                    class="search-result-list-item-address m-0 hours"
                    *ngIf="selectedPlace.formatted_address"
                  >
                    {{ selectedPlace.formatted_address }}
                  </p>
                </div>
              </div>

              <div class="row mx-auto mt-3 p-0">
                <div class="col-6 m-0 p-0 font-location">
                  <i class="bi bi-telephone-fill"></i>Phone
                </div>
                <div class="col-6 m-0 p-0 text-end">
                  <p
                    class="search-result-list-item-phone hours"
                    *ngIf="selectedPlace.formatted_phone_number"
                  >
                    {{ selectedPlace.formatted_phone_number }}
                  </p>
                </div>
              </div>

              <div class="row mx-auto mt-2 p-0">
                <div class="col-6 m-0 p-0 font-location">
                  <i class="bi bi-calendar3-range"></i>Website
                </div>
                <div class="col-6 m-0 p-0 text-end">
                  <p class="search-result-list-item-phone hours">
                    <a
                      href="{{ selectedPlace.website }}"
                      target="_blank"
                      rel="noopener noreferrer"
                      *ngIf="selectedPlace.website"
                    >
                      Website
                    </a>
                  </p>
                </div>
              </div>

              <div
                class="row mx-auto mt-2 m-0 p-0"
                *ngIf="selectedPlace.current_opening_hours"
              >
                <div class="col-md-3 m-0 p-0 col-5 font-location">
                  <i class="bi bi-clock-fill"></i>Hours
                </div>
                <div
                  class="col-md-9 m-0 p-0 col-7 text-end"
                  (click)="toggleWeekdays()"
                >
                  <p class="hours m-0">
                    <span
                      *ngIf="
                        selectedPlace.current_opening_hours.open_now;
                        else closedBlock
                      "
                    >
                      <strong>Open |</strong> Closes at
                      {{ getClosingTimeForToday() }}
                    </span>
                    <ng-template #closedBlock>
                      <strong>Closed |</strong> Opens at
                      {{ getOpeningTimeForTomorrow() }}
                    </ng-template>
                    <i
                      [ngClass]="
                        showWeekdays
                          ? 'bi bi-chevron-down'
                          : 'bi bi-chevron-right'
                      "
                    ></i>
                  </p>
                </div>
              </div>

              <div class="row mx-auto mt-2 p-0" *ngIf="showWeekdays">
                <div class="col-12 text-end">
                  <ul
                    class="list-unstyled"
                    *ngIf="selectedPlace.current_opening_hours.weekday_text"
                  >
                    <li
                      *ngFor="
                        let dayText of selectedPlace.current_opening_hours
                          .weekday_text
                      "
                      class="hours-text"
                    >
                      <strong>{{
                        dayText.slice(0, dayText.indexOf(":"))
                      }}</strong
                      >{{ dayText.slice(dayText.indexOf(":")) }}
                    </li>
                  </ul>
                </div>
              </div>
              <div #mapContainer style="height: 200px" class="mt-4"></div>
            </div>
          </div>
        </div>
        <div *ngIf="showPetSelection" class="pet-selection-wrapper">
          <!-- Add the X icon in the top right corner -->
          <div class="row">
            <div class="col d-flex justify-content-end">
              <i
                class="fs-4 bi-x-lg close-icon"
                (click)="togglePetSelection()"
              ></i>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <p class="font-size14">
                <strong
                  >Which pet would you like to add to this provider?</strong
                >
              </p>
              <form (submit)="submitPetSelection()">
                <div class="h6 mt-4">
                  <input
                    type="radio"
                    value="selectAll"
                    [(ngModel)]="selectedPetId"
                    name="selectedPet"
                    [disabled]="isAllPetsAssociatedWithSelectedPlace()"
                    id="selectAllPets"
                  />
                  <label
                    class="radio-label"
                    for="selectAllPets"
                    [class.disabled]="isAllPetsAssociatedWithSelectedPlace()"
                  >
                    Select All
                  </label>
                </div>
                <div
                  *ngFor="let pet of pets; trackBy: trackByPetId"
                  class="h6 my-4"
                >
                  <input
                    type="radio"
                    [value]="pet.id"
                    [(ngModel)]="selectedPetId"
                    name="selectedPet"
                    [attr.disabled]="
                      isPetAssociatedWithSelectedPlace(pet.id) ? true : null
                    "
                    id="pet_{{ pet.id }}"
                  />
                  <label
                    class="radio-label"
                    [for]="'pet_' + pet.id"
                    [class.disabled]="isPetAssociatedWithSelectedPlace(pet.id)"
                  >
                    {{ pet.name }}
                  </label>
                </div>
                <div class="row w-100 mt-3">
                  <div class="col-6">
                    <lib-primary-btn
                      primaryBtn="primaryBtn"
                      buttonText="Save Provider"
                      type="submit"
                      class="w-100"
                    ></lib-primary-btn>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-7 map-div p-0 m-0" *ngIf="!isSmallScreen">
    <div #secondMapContainer style="height: 800px; width: auto;"></div>
  </div>
</div>

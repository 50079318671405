import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { NGXLogger } from "ngx-logger";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ShelterService } from "../../service/shelter.service";
import { ShelterModel } from "../../models/shelter.model";
import { SharedLibraryService } from "../../../../../petszel-shared/src/lib/services/shared.config";

@Component({
  selector: 'app-terms-of-service-modal',
  templateUrl: './terms-of-service-modal.component.html',
  styleUrls: ['./terms-of-service-modal.component.css']
})
export class TermsOfServiceModalComponent implements OnInit, OnDestroy {
  shelter!: ShelterModel | null;
  termsOfServiceURL: string = '';
  loading = false;

  private termsOfServiceSubs!: Subscription;
  private manageShelterSubs!: Subscription;

  constructor(private logger: NGXLogger,
              private shelterService: ShelterService,
              private activeModalService: NgbActiveModal,
              private sharedLibraryService: SharedLibraryService) {
  }

  ngOnInit(): void {
    this.shelterService.shelterObs$.subscribe(shelter => this.shelter = shelter);
    this.buildTOSUrl();
  }

  ngOnDestroy(): void {
    if (this.manageShelterSubs) { this.manageShelterSubs.unsubscribe(); }
    if (this.termsOfServiceSubs) { this.termsOfServiceSubs.unsubscribe(); }
  }

  acceptTerms(): void {
    this.loading = true;
    const shelterData: ShelterModel = {
      ...this.shelter as ShelterModel,
      acceptedTerms: true
    };

    this.manageShelterSubs = this.shelterService.updateShelter(shelterData).subscribe({
      next: () => {
        this.shelterService.getShelter().subscribe({
          next: () => {
            this.loading = false;
            this.activeModalService.close();
          },
          error: () => {
            location.reload();
          }
        })
      },
      error: err => {
        this.loading = false;
        this.logger.log('Error while accepting terms of service', err);
      }
    });

  }

  private buildTOSUrl(): void {
    const environment = this.sharedLibraryService.getSharedLibraryConfig().environmentType;
    if (environment === 'local' || environment === 'development') {
      this.termsOfServiceURL = 'https://dev.petszel.com/provider-terms-of-service';
    } else if (environment === 'beta') {
      this.termsOfServiceURL = 'https://beta.petszel.com/provider-terms-of-service';
    } else {
      this.termsOfServiceURL = 'https://petszel.com/provider-terms-of-service';
    }
  }
}

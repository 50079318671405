<ng-container *ngIf="!isLoading; else loading">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Change Password</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" class="d-flex flex-column" novalidate>
      <div class="row">
        <div class="col-md-6 col-sm-12 mb-3"
             [ngClass]="{ 'has-errors':
                (form.controls['existingPassword'].touched
                && form.controls['existingPassword'].errors) || incorrectPassword  }">
          <div class="form-floating">
            <input type="password"
                   class="form-control"
                   id="floatingExistingPassword"
                   placeholder="Enter Existing Password"
                   #eP
                   [ngClass]="{ 'is-invalid':
                   (form.controls['existingPassword'].touched
                   && form.controls['existingPassword'].errors) || incorrectPassword }"
                   formControlName="existingPassword">
            <label for="floatingExistingPassword">Enter Existing Password <span class="red-text">*</span></label>
            <i class="bi password-eye-icon"
               (click)="togglePassword(eP)"
               [ngClass]="{ 'bi-eye' : eP.type === 'password',
               'bi-eye-slash' : eP.type === 'text' }">
            </i>
          </div>
          <div class="invalid-feedback-custom"
               *ngIf="incorrectPassword">
            Your existing password is incorrect.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-3"
             [ngClass]="{ 'has-errors': form.controls['newPassword'].touched && form.controls['newPassword'].errors }">
          <div class="form-floating">
            <input type="password"
                   class="form-control"
                   id="newPassword"
                   #nP
                   placeholder="Enter New Password"
                   [ngClass]="{ 'is-invalid': form.controls['newPassword'].touched && form.controls['newPassword'].errors }"
                   formControlName="newPassword">
            <label for="newPassword">Enter New Password <span class="red-text">*</span></label>
            <i class="bi password-eye-icon"
               (click)="togglePassword(nP)"
               [ngClass]="{ 'bi-eye' : nP.type === 'password',
               'bi-eye-slash' : nP.type === 'text' }">
            </i>
          </div>
          <div class="invalid-feedback-custom"
               *ngIf="form.controls['newPassword'].touched && form.controls['newPassword'].errors">
            This password does not meet the criteria. Password has to include a minimum of 10 characters, numbers and special characters.
          </div>
        </div>

        <div class="col-md-6 mb-3"
             [ngClass]="{ 'has-errors': form.controls['newPassword2'].touched && form.controls['newPassword2'].errors }">
          <div class="form-floating">
            <input type="password"
                   class="form-control"
                   id="newPassword2"
                   #cNP
                   placeholder="Confirm New Password"
                   [ngClass]="{ 'is-invalid': form.controls['newPassword2'].touched && form.controls['newPassword2'].errors }"
                   formControlName="newPassword2">
            <label for="newPassword2">Confirm New Password <span class="red-text">*</span></label>
            <i class="bi password-eye-icon"
               (click)="togglePassword(cNP)"
               [ngClass]="{ 'bi-eye' : cNP.type === 'password',
               'bi-eye-slash' : cNP.type === 'text' }">
            </i>
          </div>
          <div class="invalid-feedback-custom"
               *ngIf="form.controls['newPassword2'].touched && form.controls['newPassword2'].errors">
            This password does not match New Password.
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer d-flex justify-content-center gap-2">
    <button class="btn btn-light-custom" (click)="close()">
      Cancel
    </button>
    <button class="btn btn-primary-custom"
            (click)="submit()"
            [disabled]="!form.valid">
      Submit
    </button>
  </div>
</ng-container>

<ng-template #loading>
  <lib-loading></lib-loading>
</ng-template>

<div class="input-group mb-3 w-25 mt-3" *ngIf="filteredPartners.length > 0">
  <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchTerm" (input)="filterPartners()" />
</div>

<table *ngIf="filteredPartners.length > 0" class="table table-striped table-bordered">
  <thead>
    <tr>
      <th scope="col" (click)="sortTable('name')">Name</th>
      <th scope="col" (click)="sortTable('address')">Address</th>
      <th scope="col" (click)="sortTable('website')">Website</th>
      <th scope="col" (click)="sortTable('category')">Category</th>
      <th scope="col" (click)="sortTable('secondaryCategories')">Secondary Category</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let partner of filteredPartners">
      <td>{{ partner.name }}</td>
      <td>
        {{ partner.address.streetLine1 }}, {{ partner.address.city }},
        {{ partner.address.stateProvince }} {{ partner.address.zipCode }}
      </td>
      <td><a *ngIf="partner.website" href="{{partner.website}}" target="_blank" rel="noopener noreferrer">Website</a></td>
      <td>
        <span *ngIf="!partner.isEditing" class="badge bg-primary">{{ partner.category }}</span>
        <select *ngIf="partner.isEditing" [(ngModel)]="partner.category" class="form-select">
          <option *ngFor="let category of categories" [ngValue]="category.key">{{ category.value }}</option>
        </select>
      </td>
      <td>
        <ng-container *ngIf="!partner.isEditing">
          <span *ngFor="let secondaryCategory of partner.secondaryCategories" class="badge bg-secondary">{{ secondaryCategory }}</span>
        </ng-container>
        <div *ngIf="partner.isEditing">
          <select [(ngModel)]="partner.secondaryCategory" class="form-select">
            <option value="">None</option>
            <option *ngFor="let category of categories" [ngValue]="category.key">{{ category.value }}</option>
          </select>
        </div>
      </td>
      <td>
        <div class="d-flex">
          <button *ngIf="!partner.isEditing" type="button" class="btn btn-sm btn-primary me-2 h-auto" (click)="editPartner(partner)">
            <i class="bi bi-pencil-square fs-6 text-white"></i>
          </button>
          <button *ngIf="partner.isEditing" type="button" class="btn btn-sm btn-success me-2" (click)="savePartner(partner)"><i class="bi bi-save fs-6 text-white"></i></button>
          <button *ngIf="partner.isEditing" type="button" class="btn btn-sm btn-secondary me-2" (click)="cancelEdit(partner)"><i class="bi bi-x-square fs-6 text-white"></i></button>
          <button *ngIf="!partner.isEditing" type="button" class="btn btn-sm btn-danger h-auto" (click)="deletePartner(partner)">
            <i class="fs-6 bi-trash3 text-white"></i>
          </button>
        </div>
      </td>
      
    </tr>
  </tbody>
</table>

<div *ngIf="filteredPartners.length === 0" class="alert alert-warning mt-3 w-50 m-auto text-center">
  You do not have any local partners selected for {{ selectedCategory }}.
</div>
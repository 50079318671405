import { ownerEnvironment as betaEnvironment } from './environments/environment.beta';
import { ownerEnvironment as devEnvironment } from './environments/environment.dev';
import { ownerEnvironment as prodEnvironment } from './environments/environment.prod';
import { ownerEnvironment as baseEnvironment } from './environments/environment';

export const appConfig = {
  applicationId: 'owner',
  beta: {
    apiUrl: betaEnvironment.apiUrl,
    beta: betaEnvironment.beta,
  },
  dev: {
    apiUrl: devEnvironment.apiUrl,
    development: devEnvironment.development,
    gaTrackingId: 'G-RRDMVNK3JH',
  },
  prod: {
    apiUrl: prodEnvironment.apiUrl,
    production: prodEnvironment.production,
    gaTrackingId: 'G-XWQ39XLC3F',
  },
    apiUrl: baseEnvironment.apiUrl,
    gaTrackingId: 'G-RRDMVNK3JH',
};

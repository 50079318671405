import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function confirmNewPasswordValidator(): ValidatorFn {
  return (control: AbstractControl) : ValidationErrors | null => {
    // control is newPassword2
    const newPassword = control.parent?.get('newPassword') as AbstractControl;
    if (control && newPassword) {
      return newPassword.value === control.value ? null : { newPasswordNotMatch: true };
    } else {
      return null;
    }
  }
}

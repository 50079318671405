<div class="petszel-partner-search-list">
    <div class="partner-selection-list">
      <ng-container *ngIf="!isLoading && !selectedItem">
        <!-- Display the list view when no item is selected -->
        <lib-provider-search-result-item
          *ngFor="let partner of partners"
          [partner]="partner"
          [categories]="getCategoriesForPartner(partner)"
          (listItemSelected)="listItemSelected($event)"
          (listItemUnselected)="listItemUnselected($event)"
          [isFavorited]="isPartnerFavorited(partner)"
        ></lib-provider-search-result-item>
      </ng-container>
    </div>
  </div>
  
  <div class="selected-item-details" *ngIf="selectedItem">

    <!-- Display the lib-provider-selected component when an item is selected -->
    <ng-container *ngIf="selectedItem">
      <div class="col background">
        <lib-provider-selected
          [selectedPartner]="selectedItem"
          [owner]="owner"
          [categories]="selectedItem.categories"          
          [distanceFromChild]="selectedItem.distance" 
          (addPartners)="addPartnersClicked()"          
          (backToList)="goBackToListView()"
        ></lib-provider-selected>
      </div>
    </ng-container>
  </div>
  
import {
  Component,
  Input,
  Output,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedDataService } from 'projects/petszel-owner/src/app/service/shared-partners-data.service';
import { BehaviorSubject, Observable, Subscription, forkJoin } from 'rxjs';
import { MyProvidersService } from 'projects/petszel-owner/src/app/service/my-providers.service';
import { NGXLogger } from 'ngx-logger';
import { OwnerFamilyService } from 'projects/petszel-owner/src/app/service/owner-family.service';
import { OwnerDataService } from 'projects/petszel-owner/src/app/service/ownerdata.service';

interface PartnerData {
  googlePlacesId: string;
  // ... other properties
}
interface Place {
  googlePlacesId?: string;
  name: string;
  rating?: number;
  formatted_address?: string;
  formatted_phone_number?: string;
  website?: string;
  photos?: Photo[];
  showWeekdays?: boolean;
  geometry?: any;
  current_opening_hours?: CurrentOpeningHours;
}
interface OpeningHours {
  date: string;
  day: number;
  time: string;
}

interface Period {
  open: OpeningHours;
  close?: OpeningHours;
}

interface ExtendedPlaceResult extends google.maps.places.PlaceResult {
  current_opening_hours?: CurrentOpeningHours;
}

interface CurrentOpeningHours {
  open_now?: boolean;
  weekday_text?: string[];
  periods?: Period[];
}

interface Photo {
  getUrl: () => string; // Adjust this if the API structure is different
  // ... other properties if necessary ...
}

@Component({
  selector: 'lib-places-list',
  templateUrl: './places-list.component.html',
  styleUrls: ['./places-list.component.css'],
})
export class PlacesListComponent implements OnInit {
  places$ = new BehaviorSubject<Place[]>([]);
  partnersData: PartnerData[] = [];
  ownerAddress: any[] = [];
  selectedPlace?: any;
  isDetailView: boolean = false;
  @Input() distanceFromParent?: number;
  showWeekdays: boolean = false;
  showPetSelection: boolean = false;
  selectedPetId: string = '';
  pets: any[] = JSON.parse(localStorage.getItem('pets') || '[]');
  @ViewChild('mapContainer') mapContainerRef!: ElementRef;
  @ViewChild('secondMapContainer') secondMapContainerRef!: ElementRef;
  @ViewChild('thirdMapContainer') thirdMapContainerRef!: ElementRef;
  secondMap!: google.maps.Map;
  thirdMap!: google.maps.Map;
  map!: google.maps.Map;
  @Input() distance!: number;
  @Input() items!: any[];
  @Output() detailViewChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  isPlaceSelected: boolean = false;
  petsToPlacesMap: { [petId: string]: string[] } = {};
  partners: any[] = [];
  processedPetsMap: { [petId: string]: 'POST' | 'PUT' } = {};
  currentPlaces: Place[] = [];
  hasSelectionChanged: boolean = false;
  areAllPetsAssociated: boolean = false;
  petPosted: { [petId: string]: boolean } = {};
  isSmallScreen: boolean = window.innerWidth < 768;
  private subscription!: Subscription;

  constructor(
    private http: HttpClient,
    private sharedData: SharedDataService,
    private cdr: ChangeDetectorRef,
    private postPartner: MyProvidersService,
    private logger: NGXLogger,
    private ownerFamilyService: OwnerFamilyService,
    private ownerDataService: OwnerDataService
  ) {
    window.addEventListener('resize', this.onResize.bind(this));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = event.target.innerWidth < 768;
    this.cdr.detectChanges(); // Ensure view is updated with the new state
  }

  ngOnInit(): void {
    this.onResize({target: window});

    this.isAllPetsAssociatedWithSelectedPlace();
    this.places$.subscribe((places) => {
      this.currentPlaces = places;
      this.currentPlaces.forEach((place) => {
    
      });

      this.initMap();
    });
    this.showListView();
    this.initMap();
    this.sharedData.partnersData$.subscribe((partners) => {
      // Step 1: Filter out partners without a googlePlacesId
      const filteredPartners = partners.filter(
        (partner) => partner.googlePlacesId
      );
      // Step 2: Filter out duplicate googlePlacesId values using a Set
      const uniqueGooglePlacesIds = [
        ...new Set(filteredPartners.map((partner) => partner.googlePlacesId)),
      ];
      // Step 3: Fetch the place details for each unique googlePlacesId
      this.fetchPlaceDetailsForAllPartners(uniqueGooglePlacesIds);
      this.logger.log('Unique Google Places Ids:', uniqueGooglePlacesIds);
    });
    this.ownerAddress = this.ownerDataService.getOwnerAddress();
  }

  fetchPetsAndPartners() {
    this.logger.log('petsToPlacesMap:', this.petsToPlacesMap);

    // Fetch pets first
    this.ownerFamilyService.getAnimalInfo().subscribe((petsData) => {
      this.pets = petsData as any[];

      // Fetch all partners for each pet in parallel
      const partnerRequests = this.pets.map((pet) =>
        this.postPartner.getPartners(pet.id)
      );

      forkJoin(partnerRequests).subscribe((partnerResults: any[]) => {
        this.logger.log('Partner results:', partnerResults);
        this.partners = partnerResults;
        for (let i = 0; i < this.pets.length; i++) {
          const petId = this.pets[i].id;
          this.petsToPlacesMap[petId] = partnerResults[i].map(
            (partner: any) => partner.googlePlacesId
          );
        }

        // Check which pets are associated with the selected place
        this.pets.forEach((pet) => {
          if (this.isPetAssociatedWithSelectedPlace(pet.id)) {
            this.logger.log(
              `Pet ID ${pet.id} is associated with selected place`
            );
          } else {
            this.logger.log(
              `Pet ID ${pet.id} is NOT associated with selected place`
            );
          }
        });

        // Extract all googlePlacesIds from partners and fetch details
        const allGooglePlacesIds: string[] = [];
        for (const partnerArray of this.partners) {
          for (const partner of partnerArray) {
            if (partner.googlePlacesId) {
              allGooglePlacesIds.push(partner.googlePlacesId);
            }
          }
        }

        // Here, you can also parallelize fetching of place details if the API allows it
        this.fetchPlaceDetailsForAllPartners(allGooglePlacesIds);
      });
    });
  }

  isAllPetsAssociatedWithSelectedPlace(): boolean {
    this.areAllPetsAssociated = this.pets.every((pet) =>
      this.isPetAssociatedWithSelectedPlace(pet.id)
    );
    return this.areAllPetsAssociated;
  }

  getPetsAssociatedWithSelectedPlace(): any[] {
    return this.pets.filter((pet) =>
      this.isPetAssociatedWithSelectedPlace(pet.id)
    );
  }

  isPetAssociatedWithSelectedPlace(petId: string): boolean {
    if (!this.selectedPlace) return false;

    return (
      this.petsToPlacesMap[petId]?.includes(
        this.selectedPlace.googlePlacesId
      ) || false
    );
  }

  trackByPetId(index: number, pet: any): string {
    return pet.id;
  }

  logPetsAndAssociatedPlaces() {
    const associations: any[] = [];

    this.pets.forEach((pet) => {
      const petId = pet.id;
      const associatedPlaceIds = this.petsToPlacesMap[petId];

      // For each associated place of a pet, map to the desired log structure
      associatedPlaceIds.forEach((placeId) => {
        associations.push({
          petId: petId,
          partnerId: placeId,
        });
      });
    });

    this.logger.log(
      'Pets and partners associated with selected place:',
      associations
    );
  }

  showDetails(place: Place): void {
    this.isDetailView = !this.isDetailView;
    this.detailViewChanged.emit(this.isDetailView);
    this.fetchPetsAndPartners();
    this.selectPlace(place); // call selectPlace within showDetails
    this.cdr.detectChanges();
  }

  selectPlace(place: Place): void {
    if (!place) {
      this.logger.log('selectPlace was called with undefined or null');
      return;
    }
    this.selectedPlace = place;
    this.fetchPetsAndPartners();
    this.initMap(); // call the initMap method to display the selected place on the map
    this.cdr.detectChanges();
  }

  showListView(): void {
    this.selectedPlace = undefined;
    this.isDetailView = false;
    this.detailViewChanged.emit(this.isDetailView);
    this.cdr.detectChanges();
  }

  fetchPlaceDetails(placeId: string) {
    const placesService = new google.maps.places.PlacesService(
      document.createElement('div')
    );

    placesService.getDetails({ placeId: placeId }, (result, status) => {
      // Corrected the placeId parameter
      if (status === google.maps.places.PlacesServiceStatus.OK && result) {
        // Cast the result to your ExtendedPlaceResult if result has the extended properties
        const extendedPlace = result as ExtendedPlaceResult;

        // You should check the definition of your ExtendedPlaceResult for isOpen
        const isOpen =
          (extendedPlace.current_opening_hours &&
            extendedPlace.current_opening_hours.open_now) ||
          false;

        const periods: Period[] =
          extendedPlace.current_opening_hours?.periods?.map((period) => {
            const defaultClose = {
              date: '',
              day: period.open.day,
              time: '2359',
            }; // Default close time for a 24h open day
            return {
              open: {
                date: '', // Placeholder, as date is not provided by API
                day: period.open.day,
                time: period.open.time,
              },
              close: period.close
                ? {
                    date: '', // Placeholder, as date is not provided by API
                    day: period.close.day,
                    time: period.close.time,
                  }
                : defaultClose, // Use defaultClose here if close is undefined
            };
          }) || [];

        const newPlace: Place = {
          googlePlacesId: result?.place_id || '',
          name: result?.name || '',
          formatted_address: result?.formatted_address || '',
          rating: result?.rating,
          website: result?.website,
          formatted_phone_number: result?.formatted_phone_number,
          photos: result?.photos?.map(
            (photo: google.maps.places.PlacePhoto) => ({
              // Specify the type for 'photo'
              getUrl: photo.getUrl.bind(photo),
            })
          ),
          geometry: result?.geometry,
          current_opening_hours: result?.opening_hours?.weekday_text
            ? {
                weekday_text: result?.opening_hours.weekday_text,
                open_now: isOpen,
                periods: periods,
              }
            : undefined,
        };

        // Get the current places from the BehaviorSubject
        const currentPlaces = this.places$.value;

        if (
          !currentPlaces.some(
            (p) =>
              p.name === newPlace.name &&
              p.formatted_address === newPlace.formatted_address
          )
        ) {
          this.places$.next([...currentPlaces, newPlace]);
        }
      } else {
        this.logger.error(
          'Error fetching place details for',
          placeId,
          ':',
          status
        );
      }
    });
  }

  fetchPlaceDetailsForAllPartners(googlePlacesIds: string[]) {
    this.logger.log('Fetching details for all partners');

    for (const googlePlacesId of googlePlacesIds) {
      this.fetchPlaceDetails(googlePlacesId);
    }
  }

  onPetSelected(event: any): void {
    this.selectedPetId = event.target.value;
  }

  async submitPetSelection() {

    const performRequestForPet = async (petId: string) => {
      if (!this.isPetAssociatedWithSelectedPlace(petId)) {
        // Only perform the POST request if the pet is not associated with the selected place
        await this.postPartner.postRecommended(petId, this.selectedPlace);

        // Update the map to include the new association
        if (this.petsToPlacesMap[petId]) {
          this.petsToPlacesMap[petId].push(this.selectedPlace.googlePlacesId);
        } else {
          this.petsToPlacesMap[petId] = [this.selectedPlace.googlePlacesId];
        }

        // Update the petPosted state for this pet
        this.petPosted[petId] = true;

        // Force the component to update
        this.cdr.detectChanges();
      }
    };

    if (this.selectedPetId === 'selectAll') {
      // If the selected value is "selectAll", iterate over all pets
      for (const pet of this.pets) {
        if (!this.isPetAssociatedWithSelectedPlace(pet.id)) {
          await performRequestForPet(pet.id);
        }
      }
    } else {
      // Else, perform the request just for the selected pet if it's not disabled
      if (!this.isPetAssociatedWithSelectedPlace(this.selectedPetId)) {
        await performRequestForPet(this.selectedPetId);
      }
    }

    // Check if all pets are associated with the selected place
    this.areAllPetsAssociated = this.isAllPetsAssociatedWithSelectedPlace();

    // Set the flag to true to indicate a selection change
    this.hasSelectionChanged = true;

    // Trigger change detection to update the view
    this.cdr.detectChanges();

    this.togglePetSelection();
  }

  togglePetSelection() {
    this.showPetSelection = !this.showPetSelection;
    this.cdr.detectChanges();

    if (!this.showPetSelection) {
      this.initMap();
    }
  }

  getClosingTime(place: Place): string {
    const today = new Date().getDay();

    const todayPeriod = place?.current_opening_hours?.periods?.find(
      (period: Period) => period.close?.day === today
    );

    if (todayPeriod?.close) {
      const time = `${todayPeriod.close.time}`;
      const closingTime = this.convertToStandardTime(time);
      return closingTime;
    }

    return '';
  }

  getOpeningTime(place: Place): string {
    const today = new Date().getDay();
    const tomorrow = (today + 1) % 7;

    const tomorrowPeriod = place.current_opening_hours?.periods?.find(
      (period: Period) => period.open.day === tomorrow
    );

    return tomorrowPeriod
      ? this.convertToStandardTime(tomorrowPeriod.open.time)
      : '';
  }

  initializeComponentState(): void {
    this.isDetailView = false;
    this.selectedPlace = null;
    this.showPetSelection = false;
    this.showListView();
  }

  isRatingValid(place: Place): boolean {
    return typeof place.rating === 'number' && !isNaN(place.rating);
  }

  initMap() {
    this.cdr.detectChanges();

    // Initialize the first map
    if (this.mapContainerRef && this.mapContainerRef.nativeElement) {
      if (
        this.selectedPlace &&
        this.selectedPlace.geometry &&
        this.selectedPlace.geometry.location
      ) {
        const latitude = this.selectedPlace.geometry.location.lat();
        const longitude = this.selectedPlace.geometry.location.lng();
        const center = new google.maps.LatLng(latitude, longitude);
        const mapOptions = {
          zoom: 15,
          center: center,
          zoomControl: true,
          fullscreenControl: true,
          mapTypeControl: false,
          streetViewControl: false,
        };
        const map = new google.maps.Map(
          this.mapContainerRef.nativeElement,
          mapOptions
        );
        const marker = new google.maps.Marker({
          position: center,
          map: map,
          title: this.selectedPlace.name,
        });
      }
    }

    // Initialize the second map
    if (
      this.secondMapContainerRef &&
      this.secondMapContainerRef.nativeElement
    ) {
      if (this.currentPlaces && this.currentPlaces.length > 0) {
        let bounds = new google.maps.LatLngBounds();

        this.secondMap = new google.maps.Map(
          this.secondMapContainerRef.nativeElement,
          {
            zoom: 15,
            center: { lat: 0, lng: 0 },
            zoomControl: true,
            fullscreenControl: true,
            mapTypeControl: false,
            streetViewControl: false,
          }
        );

        this.currentPlaces.forEach((place) => {
          if (place.geometry && place.geometry.location) {
            const location = new google.maps.LatLng(
              place.geometry.location.lat(),
              place.geometry.location.lng()
            );
            const marker = new google.maps.Marker({
              position: location,
              map: this.secondMap,
              title: place.name,
            });

            const position = marker.getPosition();
            if (position) {
              bounds.extend(position);
            }
          }
        });

        this.secondMap.fitBounds(bounds);
      }
    }
    // Initialize the third map
    if (this.thirdMapContainerRef && this.thirdMapContainerRef.nativeElement) {
      if (this.currentPlaces && this.currentPlaces.length > 0) {
        let bounds = new google.maps.LatLngBounds();

        this.thirdMap = new google.maps.Map(
          this.thirdMapContainerRef.nativeElement,
          {
            zoom: 15,
            center: { lat: 0, lng: 0 },
            zoomControl: true,
            fullscreenControl: true,
            mapTypeControl: false,
            streetViewControl: false,
          }
        );

        this.currentPlaces.forEach((place) => {
          if (place.geometry && place.geometry.location) {
            const location = new google.maps.LatLng(
              place.geometry.location.lat(),
              place.geometry.location.lng()
            );
            const marker = new google.maps.Marker({
              position: location,
              map: this.thirdMap,
              title: place.name,
            });

            const position = marker.getPosition();
            if (position) {
              bounds.extend(position);
            }
          }
        });

        this.thirdMap.fitBounds(bounds);
      }
    }
  }

  goBack(): void {
    window.location.reload();
    this.cdr.detectChanges();
    this.initializeComponentState();
  }

  getStarsArray(place: Place): number[] {
    const rating = place.rating ? +place.rating : 0;
    if (!isNaN(rating) && isFinite(rating)) {
      return new Array(Math.round(rating)).fill(1);
    } else {
      return [];
    }
  }

  getOpeningTimeForTomorrow(): string | null {
    const today = new Date().getDay();
    const tomorrow = (today + 1) % 7;

    // You can use the 'Period' type here in the find method to ensure type safety
    const tomorrowPeriod =
      this.selectedPlace?.current_opening_hours?.periods?.find(
        (period: Period) => period.open.day === tomorrow
      );

    // Convert time to standard format if the period is found
    return tomorrowPeriod
      ? this.convertToStandardTime(tomorrowPeriod.open.time)
      : null;
  }

  getClosingTimeForToday(): string | null {
    const today = new Date().getDay();

    const todayPeriods =
      this.selectedPlace?.current_opening_hours?.periods?.find(
        (period: Period) => period.close?.day === today
      );

    if (todayPeriods?.close) {
      const time = `${todayPeriods.close.time}`;
      const closingTime = this.convertToStandardTime(time);
      return closingTime;
    }

    return null;
  }

  convertToStandardTime(time: string): string {
    const hour = parseInt(time.substring(0, 2));
    const minutes = time.substring(2);

    if (hour === 0) {
      return `12:${minutes} AM`;
    } else if (hour < 12) {
      return `${hour}:${minutes} AM`;
    } else if (hour === 12) {
      return `12:${minutes} PM`;
    } else {
      return `${hour - 12}:${minutes} PM`;
    }
  }

  toggleWeekdays() {
    this.showWeekdays = !this.showWeekdays;
    this.cdr.detectChanges();
  }

  toggleWeekdaySelection(place: Place) {
    if (place.showWeekdays !== undefined) {
      place.showWeekdays = !place.showWeekdays;
    } else {
      place.showWeekdays = true; // default value if it was never set
    }
    this.cdr.detectChanges();
  }

  resizeMap() {
    if (this.map) {
      google.maps.event.trigger(this.map, 'resize');
      if (
        this.selectedPlace &&
        this.selectedPlace.geometry &&
        this.selectedPlace.geometry.location
      ) {
        const location = new google.maps.LatLng(
          this.selectedPlace.geometry.location.lat(),
          this.selectedPlace.geometry.location.lng()
        );
        this.map.setCenter(location);
      }
    }
  }
}

<div class="container-fluid">
  <div class="row justify-content-center loading-row">
    <div class="col-12 text-center">
      <div id="loader">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="loading"></div>
      </div>
    </div>
  </div>
</div>

<div id="loader" [ngClass]="{ 'relative-loader': isRelative, 'absolute-loader': !isRelative }">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="loading"></div>
</div>

<ng-container *ngIf="shelter && !loading; else loadingA">

  <div class="d-flex justify-content-center p-2">
    <h4 class="modal-title">Terms of Service Update</h4>
  </div>
  <div class="modal-body">
    <p>We have updated our
      <a [href]="termsOfServiceURL" target="_blank" class="terms-of-service-link">Terms of Service</a>. These updates allow us to enhance the product with new features and services in the coming months.</p>
    <p>By continuing to use our services, you agree to the new Terms of Service.</p>
  </div>
  <div class="d-flex justify-content-center p-2">
    <button type="button" class="btn btn-primary" (click)="acceptTerms()">Accept</button>
  </div>
</ng-container>

<ng-template #loadingA>
  <lib-loading></lib-loading>
</ng-template>

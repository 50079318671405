<ng-container>
  <div class="card-google">
    <div class="card-body">
      <div class="petszel-partner-search-result-list-item">
        <div class="row">
          <div class="col">
            <!-- This is a hypothetical structure, modify it based on your layout -->
            <a (click)="togglePartnerSelect()" style="cursor: pointer">
              <h5 class="search-result-list-item-title">
                <span *ngIf="isFavorited">
                  <i class="bi bi-suit-heart-fill red-heart"></i> </span
                >{{ partner.name }}
              </h5>
              <div *ngIf="isRatingValid()" class="stars-container d-flex">
                <p class="pe-2 rating">({{ partner.rating }})</p>
                <i
                  *ngFor="let star of getStarsArray()"
                  class="bi bi-star-fill mx-1"
                ></i>
              </div>
              <div *ngFor="let category of categories" class="row category-row">
                <div class="col-auto category-col">
                  <!-- Wrapper div for image and category name -->
                  <div class="category-wrapper">
                    <img [src]="getIconForCategory(category.category)" [alt]="category.category" class="category-icon">
                    <p class="m-0 p-0">{{ category.category }}</p>
                  </div>
                </div>
                <div class="col-auto category-col">
                  <!-- Iterate over secondary categories -->
                  <div *ngFor="let secondaryCategory of category.secondaryCategories" class="category-wrapper">
                    <img [src]="getIconForCategory(secondaryCategory)" [alt]="secondaryCategory" class="category-icon">
                    <p class="m-0 p-0">{{ secondaryCategory }}</p>
                  </div>
                </div>
              </div>
              <p class="search-result-list-item-address w-100">
                {{ partner.formatted_address }}
              </p>
              <p class="search-result-list-item-phone">
                {{ partner.formatted_phone_number }}
              </p>
            </a>
            <a
              *ngIf="partner.website"
              href="{{ partner.website }}"
              target="_blank"
              rel="noopener noreferrer"
              >Website</a
            >
            <div
              class="row mx-auto mt-1 m-0 p-0"
              *ngIf="partner.current_opening_hours"
            >
              <div
                class="col-12 m-0 p-0"
                (click)="toggleWeekdaySelection(partner)"
              >
                <p class="hours m-0">
                  <span
                    *ngIf="
                      partner.current_opening_hours.open_now;
                      else closedBlock
                    "
                  >
                    <strong>Open |</strong> Closes at
                    {{ getClosingTime(partner) }}
                  </span>
                  <ng-template #closedBlock>
                    <strong>Closed |</strong> Opens at
                    {{ getOpeningTime(partner) }}
                  </ng-template>
                  <i
                    [ngClass]="
                      partner.showWeekdays
                        ? 'bi bi-chevron-down'
                        : 'bi bi-chevron-right'
                    "
                  ></i>
                </p>
              </div>
            </div>

            <div class="row mx-auto mt-2 p-0" *ngIf="partner.showWeekdays">
              <div class="col-12">
                <ul
                  class="list-unstyled"
                  *ngIf="partner.current_opening_hours?.weekday_text"
                >
                  <li
                    *ngFor="
                      let dayText of partner.current_opening_hours?.weekday_text
                    "
                    class="hours-text"
                  >
                    <strong>{{ dayText.slice(0, dayText.indexOf(":")) }}</strong
                    >{{ dayText.slice(dayText.indexOf(":")) }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

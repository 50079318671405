import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedLibraryService } from '../services/shared.config'; // Import the SharedLibraryService

@Injectable({
  providedIn: 'root'
})
export class ConfirmResetService {

  constructor(
    private http: HttpClient,
    private sharedLibraryService: SharedLibraryService // Inject the SharedLibraryService
  ) {}

  // reset password email
  postResetPassword(data: any) {
    const apiUrl = this.sharedLibraryService.getSharedLibraryConfig().apiUrl; // Get the API URL from the sharedLibraryConfig
    return this.http.post(apiUrl + 'Authorize/ResetPassword', data);
  }

  // Update password
  updatePassword(data: any) {
    const apiUrl = this.sharedLibraryService.getSharedLibraryConfig().apiUrl; // Get the API URL from the sharedLibraryConfig
    return this.http.post(apiUrl + 'Authorize/UpdatePassword', data);
  }

}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import { PetszelPasswordResetComponent } from './petszel-password-reset/petszel-password-reset.component';
import { ConfirmResetComponent } from './confirm-reset/confirm-reset.component';
import { RouterModule } from '@angular/router';
import { ResetPasswordEmailComponent } from './reset-password-email/reset-password-email.component';
import { PetszelLoginComponent } from './petszel-login/petszel-login.component'; // Import the PetszelLoginComponent
import { NavigationComponent } from './navigation/navigation.component';
import { PetszelPoweredComponent } from './petszel-powered/petszel-powered.component';
import { PrimaryBtnComponent } from './primary-btn/primary-btn.component';
import { PetszelPoweredNobrandComponent } from './petszel-powered-nobrand/petszel-powered-nobrand.component';
import { PetszelMapComponent } from './petszel-map/petszel-map.component';
import { PetszelPartnerSearchResultListComponent } from './petszel-partner-search-result-list/petszel-partner-search-result-list.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { PetszelPartnerSearchResultListItemComponent } from './petszel-partner-search-result-list-item/petszel-partner-search-result-list-item.component';
import { PetszelPartnerSearchSelectedItemComponent } from './petszel-partner-search-selected-item/petszel-partner-search-selected-item.component';
import { PetszelPartnerSummaryComponent } from '../../../petszel-staff/src/app/components/petszel-partner-summary/petszel-partner-summary.component';
import { ProviderSearchOwnerComponent } from './provider-search-owner/provider-search-owner.component';
import { ProviderSelectedComponent } from './provider-selected/provider-selected.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ProviderSearchListResultOwnerComponent } from './provider-search-list-result-owner/provider-search-list-result-owner.component';
import { PlacesListComponent } from './places-list/places-list.component';
import { ProviderSearchResultItemComponent } from './provider-search-result-item/provider-search-result-item.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { LoadingComponent } from './loading/loading.component';
import { ToastsContainerComponent } from './toasts-container/toasts-container.component';
import { NgbToastModule } from "@ng-bootstrap/ng-bootstrap";
import { FooterNavComponent } from './footer-nav/footer-nav.component';

@NgModule({
  declarations: [
    PetszelPasswordResetComponent,
    ConfirmResetComponent,
    ResetPasswordEmailComponent,
    PetszelLoginComponent,
    NavigationComponent,
    PetszelPoweredComponent,
    PrimaryBtnComponent,
    PetszelPoweredNobrandComponent,
    PetszelMapComponent,
    PetszelPartnerSearchResultListComponent,
    PetszelPartnerSearchResultListItemComponent,
    PetszelPartnerSearchSelectedItemComponent,
    PetszelPartnerSummaryComponent,
    ProviderSearchOwnerComponent,
    ProviderSelectedComponent,
    ProviderSearchListResultOwnerComponent,
    PlacesListComponent,
    ProviderSearchResultItemComponent,
    ErrorMessageComponent,
    LoadingComponent,
    ToastsContainerComponent,
    FooterNavComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    GoogleMapsModule,
    LayoutModule,
    FormsModule,
    MatDialogModule,
    NgbToastModule
  ],
  exports: [
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    PetszelPasswordResetComponent,
    ConfirmResetComponent,
    ResetPasswordEmailComponent,
    PetszelLoginComponent,
    NavigationComponent,
    FooterNavComponent,
    PetszelPoweredComponent,
    PrimaryBtnComponent,
    PetszelPoweredNobrandComponent,
    GoogleMapsModule,
    PetszelMapComponent,
    PetszelPartnerSummaryComponent,
    ProviderSearchOwnerComponent,
    ProviderSelectedComponent,
    ProviderSearchListResultOwnerComponent,
    PlacesListComponent,
    PetszelPartnerSearchResultListComponent,
    ErrorMessageComponent,
    LoadingComponent,
    ToastsContainerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PetszelSharedModule {}

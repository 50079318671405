import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent {
  @Input() message: string = 'Oops, something went wrong. Please try again or contact support@petszel.com.';
}

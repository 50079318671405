import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-petszel-powered-nobrand',
  templateUrl: './petszel-powered-nobrand.component.html',
  styleUrls: ['./petszel-powered-nobrand.component.css']
})
export class PetszelPoweredNobrandComponent {
  @Input() shelterLogoSrc!: any;
  @Input() ShelterAlt!: string;
  @Input() size: 'small' | 'large' = 'small';
  brandingDays!: any;
}

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { LocalPartnerSearchService } from '../../../../petszel-staff/src/app/service/local-partner-search.service'; // Adjust the import path
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'lib-petszel-partner-search-result-list-item',
  templateUrl: './petszel-partner-search-result-list-item.component.html',
  styleUrls: ['./petszel-partner-search-result-list-item.component.css'],
})
export class PetszelPartnerSearchResultListItemComponent {
  @Input() categories: any;
  @Input() partner: any;
  @Output() listItemUnselected: EventEmitter<any> = new EventEmitter<any>();
  @Output() listItemSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() isFavorited!: boolean;

  selectedCategory: any;
  showPartnerSelectDiv = false;

  constructor(private cdr: ChangeDetectorRef, private logger: NGXLogger) {}

  ngOnInit(): void {
    var c = this.categories;
    this.cdr.detectChanges();
  }

  cardClicked(partner: any) {
    this.listItemSelected.emit(partner);
  }

  togglePartnerSelect() {
    this.showPartnerSelectDiv = !this.showPartnerSelectDiv;
    var p = this.partner;

    this.listItemSelected.emit(p);
  }

  getStarsArray(): number[] {
    const rating = +this.partner.rating;
    if (!isNaN(rating) && isFinite(rating)) {
      return new Array(Math.round(rating)).fill(1);
    } else {
      return [];
    }
  }

  isRatingValid(): boolean {
    return (
      typeof this.partner.rating === 'number' && !isNaN(this.partner.rating)
    );
  }

  togglePartnerUnselect() {
    this.showPartnerSelectDiv = !this.showPartnerSelectDiv;
    var p = this.partner;

    this.listItemUnselected.emit(p);
  }

  itemSelected(partner: any) {
    // Here, you can call your method to get place details and photos
    this.getPlaceDetails(partner.place_id);
    this.listItemSelected.emit(partner);
  }

  private getPlaceDetails(placeId: string): void {
    const request: google.maps.places.PlaceDetailsRequest = {
      placeId: placeId,
      fields: ['photos'],
    };

    // Use the Google Places API directly
    const placesService = new google.maps.places.PlacesService(
      document.createElement('div')
    );

    placesService.getDetails(
      request,
      (
        place: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          const photos = place.photos;
          // Inside getPlaceDetails method
          if (photos && photos.length > 0) {
            // Display the first photo in your HTML
            this.partner.photoUrl = photos[0].getUrl();
            this.logger.log('Photo URL:', this.partner.photoUrl);
          }
        }
      }
    );
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from '../../owner.config';
import { Logo } from '../models/logo.interface';
import { Observable, map } from 'rxjs';
import { BrandingResponse } from '../models/branding.interface';

@Injectable({
  providedIn: 'root',
})
export class OwnerService {
  constructor(private http: HttpClient) {}

  private categoryMap: { [key: string]: string } = {
    NonGovernment: 'Shelter',
    Government: 'Shelter',
    NonGovernmentNoContract: 'Shelter',
    Rescue: 'Rescue',
    Breeder: 'Breeder',
    VeterinarianGeneralPractice: 'Vet Practice',
    VeterinarianEmergencyAndSpecialty: 'Vet Hospital',
    Other: 'Other',
  };

  getMappedShelterName(): Observable<string> {
    return this.getBranding().pipe(
      map((response: BrandingResponse) => this.categoryMap[response.category] || 'Unknown')
    );
  }

  getLogo() {
    const token = localStorage.getItem('token');
    // Set the Authorization header with the token value
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // Make the GET request with the headers
    return this.http.get<Logo[]>(appConfig.apiUrl + 'Owner/Shelter/Logos', {
      headers,
    });
  }

  getBranding(): Observable<BrandingResponse> {
    const token = localStorage.getItem('token');
    // Set the Authorization header with the token value
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // Make the GET request with the headers
    return this.http.get<BrandingResponse>(appConfig.apiUrl + 'Owner/Shelter', {
      headers,
    });
  }

  getDocs(): Observable<string> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const pdfEndpoint = appConfig.apiUrl + 'Owner/Shelter/Docs';

    // Specify the responseType as 'text' to ensure it returns a string
    return this.http.get(pdfEndpoint, { headers, responseType: 'text' });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { appConfig } from "../../shelter.config";
import { ShelterModel } from "../models/shelter.model";
import { ShelterLogoModel } from "../models/shelter-logo.model";
import { ShelterUserModel } from "../models/shelter-user.model";

@Injectable({
  providedIn: 'root'
})
export class ShelterService {
  private shelterNameSource = new BehaviorSubject<string>('');
  shelterName$ = this.shelterNameSource.asObservable();
  shelterObs$: Observable<ShelterModel | null>;

  private shelter$: BehaviorSubject<ShelterModel | null> = new BehaviorSubject<ShelterModel | null>(null);
  private token = localStorage.getItem('token');
  private headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);

  constructor(private http: HttpClient) {
    this.shelterObs$ = this.shelter$.asObservable();
  }

  getShelter(): Observable<ShelterModel> {
    const url = `${appConfig.apiUrl}Shelter`;
    return this.http.get<ShelterModel>(url, { headers: this.headers }).pipe(
      tap(shelter => {
        shelter.extractionEnabled = shelter.extractionEnabled == null ? false : shelter.extractionEnabled;
        shelter.communicationsEnabled = shelter.communicationsEnabled == null ? false : shelter.communicationsEnabled;

        // Automatically update new info in Observable
        this.shelter$.next(shelter);
        this.setShelterName(shelter.legalName); // Update shelter name
      })
    );
  }

  clearShelterName(): void {
    this.shelterNameSource.next('');
  }

  setShelterName(name: string): void {
    this.shelterNameSource.next(name);
  }

  getShelterLogos(): Observable<ShelterLogoModel[]> {
    const url = `${appConfig.apiUrl}Shelter/Logos`
    return this.http.get<ShelterLogoModel[]>(url, { headers: this.headers });
  }

  updateShelter(data: ShelterModel): Observable<any> {
    const url = `${appConfig.apiUrl}Shelter`;
    return this.http.put(url, data, { headers: this.headers, responseType: 'text' });
  }

  uploadLogo(data: any): Observable<any> {
    const url = `${appConfig.apiUrl}Shelter/Logos`;
    return this.http.post<any>(url, data, { headers: this.headers });
  }

  deleteLogo(logoId: string): Observable<any> {
    const url = `${appConfig.apiUrl}Shelter/Logos?logoId=${logoId}`;
    return this.http.delete(url, { headers: this.headers, responseType: 'text' });
  }

  getUsers(): Observable<ShelterUserModel[]> {
    const url = `${appConfig.apiUrl}Shelter/Users`;
    console.log(url, appConfig)
    return this.http.get<ShelterUserModel[]>(url, { headers: this.headers });
  }

  changePassword(data: { existingPassword: string, newPassword: string }): Observable<any> {
    const url = `${appConfig.apiUrl}Authorize/ChangePassword`;
    return this.http.post(url, data,{ headers: this.headers });
  }
}

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { PasswordValidator } from '../classes/password';
import { ConfirmResetService } from '../services/confirm-reset.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-petszel-password-reset',
  templateUrl: './petszel-password-reset.component.html',
  styleUrls: ['./petszel-password-reset.component.css']
})
export class PetszelPasswordResetComponent {
  formResetPassword!: FormGroup;
  submitted = false;
  fieldTextType: boolean = false;
  showPasswordRequirements: boolean = false; 
  isSmallScreen: boolean = false; 
  showSuccessBanner: boolean = false;

  constructor(
    private formBuilder: FormBuilder, 
    private logger: NGXLogger,
    private postPassword: ConfirmResetService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.checkScreenSize();
    this.formResetPassword = this.formBuilder.group({
      newPassword: ['', Validators.compose([
        Validators.required,
        PasswordValidator.patternValidator(/\d/, { hasNumber: true }),
        PasswordValidator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        PasswordValidator.patternValidator(/[a-z]/, { hasSmallCase: true }),
        Validators.minLength(10)]),
      ],
      confirmPasswordReset: ['', Validators.compose([Validators.required, Validators.minLength(10)])]
    },
      {
        validator: PasswordValidator.passwordMatchValidator
      });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
    this.showPasswordRequirements = false;
  }

  showRequirements() {
    const passwordControl = this.formResetPassword.get('password');
    if (passwordControl && passwordControl.value) {
      this.showPasswordRequirements = true; // Show password requirements when password has a value
    }
  }

checkScreenSize() {
  this.isSmallScreen = window.innerWidth < 576;
}
  
onSubmit() {
  this.logger.log('Submit button clicked');
  if (this.formResetPassword.invalid) {
    return;
  }
  const newPassword = this.formResetPassword.value.newPassword;
  const resetId = localStorage.getItem('resetId');
  const data = {
    newPassword: newPassword,
    resetId: resetId
  }
  
  this.postPassword.updatePassword(data).subscribe(
    result => {
      this.logger.log(result);
      this.displaySuccessBanner();
      // Navigate to the desired URL after password update
      // this.router.navigate(['/']);
    },
    error => {
      this.logger.error(error); // Log the error message
    }
  );
}

displaySuccessBanner() {
  this.showSuccessBanner = true;

  // After displaying the banner for 5 seconds, hide it and toggle the edit mode off
  setTimeout(() => {
    this.showSuccessBanner = false;
    // You might also need to manually trigger change detection if the view doesn't update
  }, 5000);
}
}


import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { ShelterService } from './shelter.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  redirectRoute: string = '/shelter-dashboard';
  badCredentials = false;

  constructor(private router: Router, private shelterService: ShelterService) {}

  isLoggedIn(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwt_decode<any>(token);
        const credentialType = decodedToken['petszel-credentialtype'];
        if (Array.isArray(credentialType)) {
          return (
            credentialType.includes('PetszelConnectShelterAdmin') ||
            credentialType.includes('PetszelConnectShelterSiteAdmin') ||
            credentialType.includes('PetszelConnectShelterStaff')
          );
        } else {
          return (
            credentialType === 'PetszelConnectShelterAdmin' ||
            credentialType === 'PetszelConnectShelterSiteAdmin' ||
            credentialType === 'PetszelConnectShelterStaff'
          );
        }
      } catch (error) {
        return false;
      }
    }
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isLoggedIn()) {
      if (this.redirectRoute === '/shelter-dashboard') {
        return true;
      } else {
        this.badCredentials = true;
        this.logout();
        return false;
      }
    } else {
      this.logout();
      return false;
    }
  }

  logout(): void {
    localStorage.removeItem('token');
    this.shelterService.clearShelterName(); // Clear shelter name on logout
    this.router.navigateByUrl('/');
  }

  redirectCallback(credentialType: string) {
    if (
      credentialType === 'PetszelConnectShelterAdmin' ||
      credentialType === 'PetszelConnectShelterSiteAdmin' ||
      credentialType === 'PetszelConnectShelterStaff'
    ) {
      this.router.navigateByUrl(this.redirectRoute).then((success) => {
        if (!success) {
          console.error('Navigation failed');
        }
      });
    }
  }

  logoutAndRedirect(): Observable<void> {
    this.shelterService.clearShelterName();
    this.logout();
    return of(undefined);
  }
}

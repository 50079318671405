import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  ViewChild,
  Input,
  HostListener,
  ChangeDetectionStrategy,
} from '@angular/core';
import { LocalPartnerSearchService } from '../../../../petszel-staff/src/app/service/local-partner-search.service';
import { NGXLogger } from 'ngx-logger';
import { PetszelMapComponent } from '../petszel-map/petszel-map.component';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { OwnerDataService } from 'projects/petszel-owner/src/app/service/ownerdata.service';
import { OwnerFamilyService } from 'projects/petszel-owner/src/app/service/owner-family.service';
import { SharedDataService } from 'projects/petszel-owner/src/app/service/shared-partners-data.service';
import { AnalyticsService } from 'projects/petszel-owner/src/app/service/google-analytics.service';
export interface OwnerData {
  address: {
    streetLine1: string;
    city: string;
    stateProvince: string;
    zipCode: string;
  };
}

export interface ownerAddress {
  streetLine1: string;
  city: string;
  stateProvince: string;
  zipCode: string;
}

interface PlaceResultWithDistance extends google.maps.places.PlaceResult {
  distance?: number;
}

@Component({
  selector: 'lib-provider-search-owner',
  templateUrl: './provider-search-owner.component.html',
  styleUrls: ['./provider-search-owner.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ProviderSearchOwnerComponent {
  @ViewChild('search') public searchElementRef!: ElementRef;
  @ViewChild(PetszelMapComponent) petszelMap!: PetszelMapComponent;
  @ViewChild('search-input') placesSearchInput!: ElementRef;
  markers: any[] = [];
  @Input() categoriesWithImages: { category: string; imageSource: string }[] =
    [];
  selectedCategoryValue: string = '';
  partners: any;
  shelters: any;
  accountId: any;
  selectedShelter: any;
  selectedPartners: any[] = [];
  validationErrors: any[] = [];
  currentPartners: any[] = [];
  shelterMarker: any;
  searchBox!: google.maps.places.SearchBox;
  latitude = 0;
  longitude = 0;
  zoom = 12;
  searchValue: string = '';
  placesService: google.maps.places.PlacesService | undefined;
  isLoading: boolean = true;
  isSmallScreen: boolean = window.innerWidth < 768;
  ownerAddress: ownerAddress | undefined;
  selectedCategory: any;
  isImageLoaded = true;
  categories: any;
  hasSearched: boolean = false;
  isItemSelected = false;
  googlePlacesIds!: Observable<string[]>;
  distance?: number;
  partnersData: any;
  @Input() isFavorited!: boolean;
  isPlaceSelected: boolean = false;
  searching: boolean = false;
  TEN_MILES_IN_METERS = 5 * 1609.34 * 2;
  TWENTYFIVE_MILES_IN_METERS = 5 * 1609.34 * 5;
  private currentSearchQuery: string = '';

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.logger.log('Resizing window. Width:', window.innerWidth);
    this.isSmallScreen = window.innerWidth < 768;
    this.logger.log('isSmallScreen:', this.isSmallScreen);
    this.cdr.detectChanges();
  }
  fetchData() {
    setTimeout(() => {
      this.isLoading = false; 
    }, 2000);
  }
  constructor(
    private localPartnerSearchService: LocalPartnerSearchService,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private cdr: ChangeDetectorRef,
    private ownerData: OwnerDataService,
    private owner: OwnerFamilyService,
    private sharedDataService: SharedDataService,
    private router: Router,
    private ngZone: NgZone,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.fetchOwnerData();

    this.sharedDataService.partnersData$.subscribe((data) => {
      // Now you have access to partnersData in your library component
      this.partnersData = data;
    });
    this.isSmallScreen = window.innerWidth < 768;
    const googlePlacesIdsString = localStorage.getItem('googlePlacesIds');
    if (googlePlacesIdsString) {
      this.googlePlacesIds = JSON.parse(googlePlacesIdsString);
      this.logger.log('Google Places IDs:', this.googlePlacesIds);
    } else {
      this.logger.warn('Google Places IDs not found in localStorage.');
    }

    this.route.paramMap.subscribe((params) => {
      this.accountId = params.get('accountId');

      var categoriesObservable =
        this.localPartnerSearchService.getPartnerCategories();

        categoriesObservable.subscribe(
          (categories: any) => {
            this.categories = categories;
            this.cdr.detectChanges(); // Trigger change detection
            this.logger.log('Categories:', categories);
          },
          (error) => {
            this.logger.error(`Failed to retrieve categories`, error);
            this.logger.error('Error fetching categories:', error); 
          }
        );
    });
  
  }

  onDetailViewChanged(isDetailView: boolean): void {
    this.isPlaceSelected = isDetailView;
  }

  getStarsArray(partnerFavorite: any): number[] {
    const rating = +partnerFavorite.rating;
    if (!isNaN(rating) && isFinite(rating)) {
      return new Array(Math.round(rating)).fill(1);
    } else {
      return [];
    }
  }

  isRatingValid(partnerFavorite: any): boolean {
    return (
      typeof partnerFavorite.rating === 'number' &&
      !isNaN(partnerFavorite.rating)
    );
  }

  handleItemSelected(event: any) {
    this.isItemSelected = true;
    this.isPlaceSelected = true;
    this.searching = false;
    this.cdr.detectChanges();
  }

  handleItemUnselected(event: any) {
    this.logger.log('Item Unselected event triggered');
    this.isItemSelected = false;
    this.isPlaceSelected = false;    
    this.logger.log('isItemSelected value:', this.isItemSelected);
    this.cdr.detectChanges();
  }

  getCategoryShortName(longName: string): string {
    const categoryMapping: Record<string, string> = {
      'Emergency Veterinary Practice (ER Vet Practice)': 'ER Clinic',
      'Veterinary Practice': 'Vet Clinic',
      'Specialty Veterinary Practice': 'Specialty Clinic',
      'Pet Supplies': 'Supplies',
    };

    return categoryMapping[longName] || longName;
  }

  itemSelected(partner: any) {
    this.searching = false;
    this.isItemSelected = true;
    this.isPlaceSelected = true;
    this.selectedPartners.push(partner);
    this.cdr.detectChanges();
  }

  itemUnselected(partner: any) {
    const index = this.selectedPartners.indexOf(partner);
    this.selectedPartners.splice(index, 1);
  }
  fetchOwnerData() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.logger.error('JWT token not found in local storage');
      return;
    }

    this.owner
      .getOwnerInfo()
      .pipe(
        retry(3),
        catchError((ownerError) => {
          this.logger.error('Error fetching owner data:', ownerError);
          return throwError(ownerError);
        })
      )
      .subscribe(
        (ownerData: OwnerData) => {
          // Set owner data in your service
          this.ownerData.setOwnerData(ownerData);

          // owner data, you can set the shelter and address
          this.setShelter(ownerData);
        },
        (ownerError) => {
          this.logger.error('Error fetching owner data:', ownerError);
        }
      );
  }

  setShelter(ownerData: OwnerData | undefined) {
    this.markers = [];
    this.partners = [];
    this.selectedPartners = [];
    this.searchValue = '';

    const geocoder = new google.maps.Geocoder();

    // If ownerData and ownerData.address are available, try geocoding
    if (ownerData && ownerData.address) {
      const address = `${ownerData.address.streetLine1} ${ownerData.address.city}, ${ownerData.address.stateProvince} ${ownerData.address.zipCode}`;

      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK' && results && results.length > 0) {
          // Successful geocode
          this.processGeocodeResults(ownerData, results);
        } else {
          // Geocode failed, so fetch the current location as a fallback
          this.useCurrentLocation();
        }
      });
    } else {
      // No owner data at all
      this.useCurrentLocation();
    }
  }

  processGeocodeResults(
    ownerData: OwnerData,
    results: google.maps.GeocoderResult[]
  ) {
    const ownerLocation = results[0].geometry.location;
    this.petszelMap?.map?.panTo(ownerLocation);

    this.shelterMarker = {
      position: {
        lat: ownerLocation.lat(),
        lng: ownerLocation.lng(),
      },
      label: {
        color: 'blue',
        text: 'THE SHELTER',
      },
      title: 'THE SHELTER',
    };

    const ownerAddressArray = [
      ownerData.address!.streetLine1,
      ownerData.address!.city,
      ownerData.address!.stateProvince,
      ownerData.address!.zipCode,
    ];

    // Save the owner address in the service
    this.ownerData.setOwnerAddress(ownerAddressArray);

    // Set search box bounds
    this.searchBox.setBounds(
      this.petszelMap?.map?.getBounds() as google.maps.LatLngBounds
    );
    this.route.queryParams.subscribe(params => {
      const category = params['category'];
      if (category) {
        // Wrap the searchByCategory call within setTimeout
        this.searching = true;

        setTimeout(() => {
          this.searchByCategory({ value: category });
          this.searching = true;

        }, 100); 
      }
    });
  }

  useCurrentLocation() {
    this.logger.warn(
      'Owner address is undefined or geocoding failed. Attempting to use current location...'
    );

    // Try to get the user's current location
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.petszelMap?.map?.panTo(currentLocation);

          this.shelterMarker = {
            position: currentLocation,
            label: {
              color: 'blue',
              text: 'THE SHELTER',
            },
            title: 'THE SHELTER',
          };

          this.searchBox.setBounds(
            this.petszelMap?.map?.getBounds() as google.maps.LatLngBounds
          );
        },
        (error) => {
          this.logger.error('Failed to get current location:', error);
        }
      );
    } else {
      this.logger.error('Geolocation is not available.');
    }
  }

  focusSearchBox(): void {
    this.searchElementRef.nativeElement.focus();
  }
  
  searchByCategory(category: any): void {
    if (category) {
      this.updateSearchValue(category);
  
      // Delayed execution to ensure updateSearchValue has been processed
      setTimeout(() => {
        this.onSearch();
        google.maps.event.trigger(this.searchBox, 'places_changed');
        this.selectedCategoryValue = this.searchValue;
  
        this.handlePlacesChanged(this.searchBox.getPlaces());
        this.ngZone.run(() => {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { category: this.searchValue },
            queryParamsHandling: 'merge',
            replaceUrl: true
          });
        });
      }, 100);
  
      const dummyMap = new google.maps.Map(document.createElement('div')); // Create a dummy map
      const pService = new google.maps.places.PlacesService(dummyMap);
  
      const shelterLatLng = new google.maps.LatLng(this.shelterMarker.position.lat, this.shelterMarker.position.lng);
  
      const request = {
        location: shelterLatLng,
        keyword: this.searchValue, // Use updated searchValue
        rankBy: google.maps.places.RankBy.DISTANCE
      };
  
      pService.nearbySearch(request, (result) => {
        if (result) {
          this.handlePlacesChanged(result);
        }
      });
    }
  }
  
  updateSearchValue(category: any): void {
    // Set the value directly in the search box
    this.searchValue = category.value;
    this.analyticsService.trackUserSearch(this.searchValue);
  
    // Replace "Training" with "Animal Training" before initiating the search
    if (this.searchValue === 'Training') {
      this.searchValue = 'Pet Training';
    }
  }
   
  onKeyup(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.performSearch();
    }
  }

  onSearch(): void {
    this.performSearchCategory();
    this.searching = true;
  }

  performSearchCategory(): void {
    this.searchBox.getPlaces();
    google.maps.event.trigger(this.searchBox, 'places_changed');
    this.searchElementRef.nativeElement.focus();
    this.isLoading = false;
    this.hasSearched = true;
  }

  performSearch(): void {
    // Ensure that currentSearchQuery is not empty
    if (!this.currentSearchQuery) {
      console.error('Search query is empty or undefined');
      return;
    }
  
    const dummyMap = new google.maps.Map(document.createElement('div')); // Create a dummy map
    const pService = new google.maps.places.PlacesService(dummyMap);
  
    const shelterLatLng = new google.maps.LatLng(this.shelterMarker.position.lat, this.shelterMarker.position.lng);
  
    const request = {
      location: shelterLatLng,
      rankBy: google.maps.places.RankBy.DISTANCE,
      keyword: this.currentSearchQuery // Ensure this is a valid string
    };
  
    pService.nearbySearch(request , (result) => {
      if (result) {
        this.handlePlacesChanged(result);
      }
    });
    this.searchBox.getPlaces();
    google.maps.event.trigger(this.searchBox, 'places_changed');
    this.searchElementRef.nativeElement.focus();
    this.isLoading = false;
    this.hasSearched = true;
  }

  ngAfterViewInit(): void {
    let debounceTimer: number | null = null;
  
    // Create a dummy element for initializing searchBox
    const dummyElement = document.createElement('input');
    this.searchBox = new google.maps.places.SearchBox(dummyElement);
  
    // Add keyup event listener
    this.searchElementRef.nativeElement.addEventListener('keyup', (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.currentSearchQuery = this.searchElementRef.nativeElement.value;
        this.analyticsService.trackUserSearch(this.currentSearchQuery);
        this.performSearch(); // Directly call performSearch on Enter
        return; // Exit early to avoid debounce on Enter key
      }
  
      if (debounceTimer !== null) {
        clearTimeout(debounceTimer);
      }
      debounceTimer = setTimeout(() => {
        this.currentSearchQuery = this.searchElementRef.nativeElement.value;
        this.analyticsService.trackUserSearch(this.currentSearchQuery);
        // Optionally call performSearch here if you want to trigger the search on other keyups
      }, 500) as unknown as number;
    });
  
    // Add 'places_changed' event listener to the searchBox
    this.searchBox.addListener('places_changed', () => {
      this.cdr.detectChanges();
  
      const places = this.searchBox.getPlaces();
      if (!places) {
        return; // Exit if places is undefined
      }
  
      let searchQuery: string;
  
      if (places.length > 0) {
        searchQuery = places[0].name || places[0].formatted_address || '';
      } else {
        searchQuery = this.searchElementRef.nativeElement.value;
      }
  
      this.analyticsService.trackUserSearch(searchQuery);
  
      this.handlePlacesChanged(places);
    });
  }
  
  handlePlacesChanged(places: google.maps.places.PlaceResult[] | undefined): void {
    if (!places || places.length === 0) return;
  
    this.markers = [];
    this.partners = [];
  
    const bounds = new google.maps.LatLngBounds();
    const storedGooglePlacesIdsString = localStorage.getItem('googlePlacesIds');
    let storedGooglePlacesIds: string[] = [];
    if (storedGooglePlacesIdsString) {
      storedGooglePlacesIds = JSON.parse(storedGooglePlacesIdsString);
    }
  
    const shelterLatLng = this.shelterMarker?.position
      ? new google.maps.LatLng(this.shelterMarker.position.lat, this.shelterMarker.position.lng)
      : null;
  
    let placesWithinLimit: google.maps.places.PlaceResult[] = [];
  
    const processPlace = (place: google.maps.places.PlaceResult, distanceLimit: number): boolean => {
      if (!place.geometry || !place.geometry.location) return false;
  
      const placeLatLng = new google.maps.LatLng(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      );
  
      if (shelterLatLng) {
        let distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(shelterLatLng, placeLatLng);
  
        if (distanceInMeters <= distanceLimit) {
          placesWithinLimit.push(place);
          return true;
        }
      }
      return false;
    };
  
    places.forEach(place => processPlace(place, this.TEN_MILES_IN_METERS));
    if (placesWithinLimit.length < 1) {
      placesWithinLimit = [];
      places.forEach(place => processPlace(place, this.TWENTYFIVE_MILES_IN_METERS));
    }
    
    const promises: Promise<google.maps.places.PlaceResult>[] = [];
  
    placesWithinLimit.forEach((place: google.maps.places.PlaceResult) => {
      if (!place.geometry || !place.geometry.location) return;
  
      const placeLatLng = new google.maps.LatLng(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      );
  
      bounds.extend(placeLatLng);
  
      const marker = {
        position: {
          lat: placeLatLng.lat(),
          lng: placeLatLng.lng(),
        },
        label: {
          color: 'red',
          text: place.name || '',
        },
        title: place.name || '',
        distance: shelterLatLng
          ? google.maps.geometry.spherical.computeDistanceBetween(shelterLatLng, placeLatLng) / 1000
          : 0, // Convert to km for consistency
      };
  
      this.markers.push(marker);
  
      const dummyMap = new google.maps.Map(document.createElement('div'));
      const pService = new google.maps.places.PlacesService(dummyMap);
      const request: google.maps.places.PlaceDetailsRequest = {
        placeId: place.place_id || '',
      };
  
      const promise = new Promise<google.maps.places.PlaceResult>(
        (resolve, reject) => {
          pService.getDetails(
            request,
            (
              placeDetail: google.maps.places.PlaceResult | null,
              status: google.maps.places.PlacesServiceStatus
            ) => {
              if (
                status === google.maps.places.PlacesServiceStatus.OK &&
                placeDetail
              ) {
                resolve(placeDetail);
              } else {
                reject(`Failed to get details for place ID ${place.place_id}`);
              }
            }
          );
        }
      );
  
      promises.push(promise);
    });
  
    Promise.all(promises)
    .then((placesDetails: google.maps.places.PlaceResult[]) => {
      // Debugging: Log the lengths to ensure they match

      const placesDetailsWithDistance: PlaceResultWithDistance[] = placesDetails.map(
        (place, index) => {
          // Check if corresponding marker exists
          const marker = this.markers[index];
          if (!marker) {
            console.error(`No marker found for place at index ${index}`);
            return { ...place, distance: 0 }; // or handle the error as needed
          }
          return { ...place, distance: marker.distance || 0 };
        }
      );
  
        const storedPartners = placesDetailsWithDistance.filter((p) =>
          storedGooglePlacesIds.includes(p.place_id || '')
        );
  
        const newPartners = placesDetailsWithDistance.filter(
          (p) => !storedGooglePlacesIds.includes(p.place_id || '')
        );
  
        this.partners = storedPartners.concat(newPartners);
        if (!bounds.isEmpty()) {
          this.petszelMap?.map?.fitBounds(bounds);
        }
        this.isLoading = false;
        this.cdr.detectChanges();
      })
      .catch((err) => this.logger.error(err));
  }
  

  onInputChange(): void {
    if (!this.searchValue.trim().length) {
      this.hasSearched = this.searchValue.trim() !== '';
      this.onSearch();
    }
  }

  addPartners() {
    this.validationErrors = [];
    const dummyMap = new google.maps.Map(document.createElement('div')); // Create a dummy map
    const pService = new google.maps.places.PlacesService(dummyMap);
    var accountId = this.selectedShelter.accountId;

    if (!accountId) {
      var error = {
        message: `You must select a category for partner: ${value.name}`,
      };
      this.validationErrors.push(error);
      return;
    }

    if (this.selectedPartners.length > 0) {
      for (let i = 0; i < this.selectedPartners.length; i++) {
        var value = this.selectedPartners[i];
        if (!value.selectedCategory) {
          var error = {
            message: `You must select a category for partner: ${value.name}`,
          };
          this.validationErrors.push(error);
          break;
        }

        let selectedSecondaryCategories: string[] = [];

        const request = {
          placeId: value.place_id,
        };

        pService.getDetails(request, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            var partnerToAdd = {
              accountId: accountId,
              category: value.selectedCategory,
              name: place?.name,
              secondaryCategories: selectedSecondaryCategories,
              address: this.parseAddressComponent(place?.address_components),
              phone: {
                phoneNumber: place?.formatted_phone_number,
                isMobile: false,
                okayToSendSMS: false,
                description: 'test',
              },
              email: '',
              website: place?.website,
              googlePlacesId: place?.place_id,
            };

            if (value.selectedSecondaryCategory) {
              partnerToAdd.secondaryCategories.push(
                value.selectedSecondaryCategory
              );
            }

            this.localPartnerSearchService
              .postShelterPartners(this.selectedShelter.accountId, partnerToAdd)
              .subscribe(
                (response: any) => {
                  this.selectedPartners.splice(i, 1);

                  var partnersIndex = this.partners.findIndex(
                    (x: any) => x.place_id == value.place_id
                  );
                  this.partners.splice(partnersIndex, 1);
                },
                (error) => {
                  this.logger.error(`Failed to retrieve categories`, error);
                }
              );
          } else {
            this.logger.error(`Error retrieving place details: ${status}`);
          }
        });
      }
    }
  }

  yourMapOptions: google.maps.MapOptions = {
    zoomControl: true,
    fullscreenControl: true,
    mapTypeControl: false, // Hide the map type control (Satellite view)
    streetViewControl: false,  
  };  

  parseAddressComponent(addressComponent: any) {
    var streetNumber;
    var route;
    var city;
    var state;
    var zip;
    var country;

    $.each(addressComponent, function (i, address_component) {
      if (address_component.types[0] == 'street_number') {
        streetNumber = address_component.long_name;
      }

      if (address_component.types[0] == 'route') {
        route = address_component.long_name;
      }

      if (address_component.types[0] == 'locality') {
        city = address_component.long_name;
      }

      if (address_component.types[0] == 'administrative_area_level_1') {
        state = address_component.long_name;
      }

      if (address_component.types[0] == 'country') {
        country = address_component.long_name;
      }

      if (address_component.types[0] == 'postal_code') {
        zip = address_component.long_name;
      }
    });

    return {
      streetLine1: streetNumber + ' ' + route,
      streetLine2: '',
      city: city,
      stateProvince: state,
      zipCode: zip,
      country: country,
    };
  }
}

import { Injectable, Inject } from '@angular/core';
import { SHARED_LIBRARY_CONFIG } from './shared.factory';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class SharedLibraryService {
  constructor(@Inject(SHARED_LIBRARY_CONFIG) private sharedLibraryConfig: any, private logger: NGXLogger) {
    this.logger.log('Shared Library Config:', sharedLibraryConfig);
    this.logger.log('Backend API URL:', sharedLibraryConfig.apiUrl);
    this.checkIfProductionUrl();
  }

  getSharedLibraryConfig(): any {
    return this.sharedLibraryConfig;
  }

  private checkIfProductionUrl(): void {
    const isProductionUrl = this.sharedLibraryConfig.environmentType === 'production';
    this.logger.log('Is Production URL:', isProductionUrl);
  }
}


<div class="petszel-local-partner-search" *ngIf="!isSmallScreen">
  <!-- "Browse Providers" section -->
  <div class="row" *ngIf="!isItemSelected && !isPlaceSelected">
    <div class="col-md-2">
      <a routerLink="/providers" class="text-black">
        <strong> 
          <i class="bi bi-chevron-compact-left"></i>
          Back 
        </strong>
      </a>
    </div>
  </div>

  <!-- Original layout for large screens (visible on medium and larger screens) -->
    <div class="row" *ngIf="!isItemSelected && !isPlaceSelected">
      <div class="col-md-4 pt-3">
        <h3>{{ selectedShelter?.businessName }} Browse Providers</h3>
      </div>
    </div>
    <div class="row" *ngIf="!isItemSelected && !isPlaceSelected">
      <div class="col-md-8">
        <div class="d-flex">
          <div *ngFor="let category of categories" class="category">
            <a
              href="javascript:void(0);"
              (click)="searchByCategory(category)"
              class="category-link"
            >
              <!-- Filter the correct image source based on the category value -->
              <div *ngFor="let categoryWithImage of categoriesWithImages">
                <ng-container
                  *ngIf="categoryWithImage.category === category.value"
                >
                  <img
                    [src]="categoryWithImage.imageSource"
                    alt="{{ category.value }} Icon"
                    style="margin: 15px"
                  />
                </ng-container>
              </div>
              <span class="icon-text py-0">{{
                getCategoryShortName(category.value)
              }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row browse-padding">
      <div class="col-xxl-5 col-md-6">
        <div class="row d-flex justify-content-center" *ngIf="!isItemSelected && !isPlaceSelected">
          <div class="col ps-0">
            <form (submit)="$event.preventDefault()">
              <div class="input-wrapper">
                <i
                  class="bi bi-search search-icon"
                  (click)="focusSearchBox()"
                ></i>
                <input
                  #search
                  class="form-control search-box"
                  [value]="searchValue"
                  (input)="onInputChange()"
                  (keyup)="onKeyup($event)"
                  id="search-input"
                  aria-describedby="emailHelp"
                  placeholder="Search"
                />
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="row" *ngIf="!searching">
          <div class="col p-0 m-0">
            <lib-places-list  
            (detailViewChanged)="onDetailViewChanged($event)"              
            ></lib-places-list>
          </div>
        </div> -->

        <div class="row d-flex justify-content-center">
          <div class="col m-0 p-0">
            <lib-provider-search-list-result-owner
            [isLoading]="isLoading"
            (itemSelected)="itemSelected($event)"
            (addPartners)="addPartners()"
            (itemUnselected)="handleItemUnselected($event)"
            [partners]="partners"
            [distanceFromParent]="distance"
            [selectedPartners]="selectedPartners"
            [validationErrors]="validationErrors"
            [categories]="categories"
            [selectedCategory]="selectedCategoryValue">
          </lib-provider-search-list-result-owner>
          
          </div>
        </div>
      </div>
      <div class="col-xxl-7 col-md-6 p-0 m-0">
        <lib-petszel-map
          [latitude]="latitude"
          [longitude]="longitude"
          [mapZoom]="zoom"
          [shelterMarker]="shelterMarker"
          [markers]="markers"
          [customMapOptions]="yourMapOptions"
        ></lib-petszel-map>
      </div>
    </div>
</div>


<div class="petszel-local-partner-search p-3" *ngIf="isSmallScreen">
  <!-- "Browse Providers" section -->
  <div class="row d-flex flex-row mt-4 mb-3" *ngIf="!isItemSelected && !isPlaceSelected">
    <div class="col text-center">
      <a routerLink="/browse-providers" class="link-text" routerLinkActive="active-link"                     
      [queryParams]="{ category: 'Veterinary Practice' }"
      >Browse Providers</a>
  </div>
  <div class="col text-center">
      <a routerLink="/providers" class="link-text" routerLinkActive="active-link">My Providers</a>
  </div>
  <!-- <div class="col-4">
      <a class="text-black link-text disabled-link">Manage Data</a>
  </div> -->
  
     
  </div>
  <!-- Search bar (for small screens) -->

  <div class="row" *ngIf="!isItemSelected && !isPlaceSelected">
    <div class="">
      <strong>Categories</strong>
    </div>
    <div class="row m-auto">
      <div class="col-12">
        <div class="d-flex justify-content-between">
          <ng-container *ngFor="let category of categories">
            <ng-container
              *ngIf="
                getCategoryShortName(category.value) !== 'ER Clinic' &&
                getCategoryShortName(category.value) !== 'Specialty Clinic'
              "
            >
              <div class="category text-center">
                <a
                  href="javascript:void(0);"
                  (click)="searchByCategory(category)"
                  class="category-link"
                >
                  <!-- Filter the correct image source based on the category value -->
                  <div *ngFor="let categoryWithImage of categoriesWithImages">
                    <ng-container
                      *ngIf="categoryWithImage.category === category.value"
                    >
                      <img
                        [src]="categoryWithImage.imageSource"
                        alt="{{ category.value }} Icon"
                      />
                    </ng-container>
                  </div>
                  <span class="icon-text text-center">{{
                    getCategoryShortName(category.value)
                  }}</span>
                </a>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col">
      <!-- Search bar -->
      <form (submit)="$event.preventDefault()">
        <div class="input-wrapper">
          <i class="bi bi-search search-icon" (click)="focusSearchBox()"></i>
          <input
            #search
            class="form-control search-box mb-2"
            [value]="searchValue"
            (input)="onInputChange()"
            (keyup)="onKeyup($event)"
            id="search-input-sm"
            aria-describedby="emailHelp"
            placeholder="Search"
          />
        </div>
      </form>
    </div>
    
  </div>

  <!-- Map and Result list section (for small screens) -->
  <div class="row" *ngIf="!isItemSelected && !isPlaceSelected">
    <div class="col">
      <lib-petszel-map
        [latitude]="latitude"
        [longitude]="longitude"
        [mapZoom]="zoom"
        [shelterMarker]="shelterMarker"
        [markers]="markers"
        [customMapOptions]="yourMapOptions"
      ></lib-petszel-map>
    </div>
  </div>
  <!-- <div class="row" *ngIf="!searching">
    <div class="col">
      <lib-places-list  
      (detailViewChanged)="onDetailViewChanged($event)"      
      ></lib-places-list>
    </div>
  </div> -->

  <div class="row d-flex justify-content-center">
    <div class="col p-0 m-0">
      <lib-provider-search-list-result-owner
      [isLoading]="isLoading"
      (itemSelected)="itemSelected($event)"
      (addPartners)="addPartners()"
      (itemUnselected)="handleItemUnselected($event)"
      [partners]="partners"
      [distanceFromParent]="distance"
      [selectedPartners]="selectedPartners"
      [validationErrors]="validationErrors"
      [categories]="categories"
      ></lib-provider-search-list-result-owner>
    </div>
  </div>
</div>


<!-- <div role="status" class="text-center mt-3">
  <div *ngFor="let partnerFavorite of partnersData" class="card" style="width: 18rem; margin: 10px auto;">
    <div class="card-body">
      <h5 class="card-title">
        <span *ngIf="isFavorited">
          <i class="bi bi-suit-heart-fill red-heart"></i>
        </span>
        {{partnerFavorite.name}}
      </h5>
      <p class="search-result-list-item-address w-100">
        {{partnerFavorite.address.streetLine1}}<br>
        {{partnerFavorite.address.streetLine2}}<br>
        {{partnerFavorite.address.city}}, {{partnerFavorite.address.stateProvince}} {{partnerFavorite.address.zipCode}}
      </p>
      <p class="search-result-list-item-phone">{{partnerFavorite.phone.phoneNumber}}</p>
      <a *ngIf="partnerFavorite.website" href="{{partnerFavorite.website}}" target="_blank" rel="noopener noreferrer">Website</a>
    </div>
  </div>
</div> -->




import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import dayjs from "dayjs";

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<NgbDateStruct> {
  readonly DELIMITER = '-';

  fromModel(value: string | null | NgbDateStruct): NgbDateStruct | null {
    if (value) {
      if (typeof value === 'string') {
        const date = value.split(this.DELIMITER);
        return {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parseInt(date[2], 10),
        };
      } else {
        return value;
      }
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): NgbDateStruct | null {
    return date ? { day: date.day, month: date.month, year: date.year } as NgbDateStruct : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = dayjs(value);
      return {
        day: date.date(),
        month: date.month() + 1,
        year: date.year(),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? (date.month < 10 ? `0${date.month}` : date.month)
      + this.DELIMITER + (date.day < 10 ? `0${date.day}` : date.day)
      + this.DELIMITER + date.year : '';
  }
}

<ng-container >
    <div class="card">
        <div class="card-body">
          <div class="petszel-partner-search-result-list-item">
            <div class="row">
              <div class="col-9">
                <h5 class="search-result-list-item-title">{{partner.name}}</h5>
                <div *ngIf="isRatingValid()" class="stars-container d-flex">
                  <p class="pe-2 rating">({{partner.rating}})</p>
                  <i *ngFor="let star of getStarsArray()" class="bi bi-star-fill mx-1"></i>
                </div>
                <p class="search-result-list-item-address w-100">{{partner.formatted_address}}</p>
                <p class="search-result-list-item-phone">{{partner.formatted_phone_number}}</p>
                <br/>
                <ul *ngIf="partner.current_opening_hours?.weekday_text" >
                  <li *ngFor="let hours of partner.current_opening_hours?.weekday_text">{{hours}}</li>
                </ul>
                <br/>
                <a *ngIf="partner.website" href="{{partner.website}}" target="_blank" rel="noopener noreferrer">Website</a>
              </div>
              <div class="col-3">
                <lib-primary-btn *ngIf="showPartnerSelectDiv" class="search-result-list-item-btn" primaryBtn="primaryBtnSm" buttonText="Unselect" (click)="togglePartnerUnselect()"></lib-primary-btn>
                <lib-primary-btn *ngIf="!showPartnerSelectDiv && !partner.currentPartner" class="search-result-list-item-btn" primaryBtn="primaryBtnSm" buttonText="Add" (click)="togglePartnerSelect()"></lib-primary-btn>
                <p *ngIf="partner.currentPartner">Current Partner</p>
              </div> 
            </div>       
          </div>
        </div>
      </div>
</ng-container>

  

 <!-- Commented section can be uncommented if needed 
            <div class="petszel-partner-search-result-list-item-selected row" *ngIf="showPartnerSelectDiv">
                <div class="col-md-6">
                    <strong>Primary Category</strong>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option *ngFor="let category of categories" [value]="category.value">{{ category.key }}</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <strong>Secondary Category</strong>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option *ngFor="let category of categories" [value]="category.value">{{ category.key }}</option>
                    </select>
                </div>
            </div>
            -->

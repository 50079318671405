import { Component } from '@angular/core';

@Component({
  selector: 'lib-PetszelShared',
  template: `
    <p>
      petszel-shared works!
    </p>
  `,
  styles: [
  ]
})
export class PetszelSharedComponent {

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'lib-petszel-partner-search-result-list',
  templateUrl: './petszel-partner-search-result-list.component.html',
  styleUrls: ['./petszel-partner-search-result-list.component.css']
})
export class PetszelPartnerSearchResultListComponent {
  @Input() partners: any;
  @Input() categories: any;
  @Input() selectedPartners: any[] = [];
  @Input() validationErrors: any[] = [];
  @Input() ownerItem: boolean = false;  
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemUnselected: EventEmitter<any> = new EventEmitter<any>();
  @Output() addPartners: EventEmitter<any> = new EventEmitter<any>();
  @Input() isLoading!: boolean;
  @Input() owner!: any;
  @Input() owner1!: any;
  @Input() loading?: boolean = false;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.cdr.detectChanges();
    
      var x = this.categories
  }

  addPartnersClicked()
  {
    this.addPartners.emit(this.selectedPartners);      
  }

  listItemSelected(partner: any){
    this.itemSelected.emit(partner);
  }

  listItemUnselected(partner: any){
    this.itemUnselected.emit(partner);
  }
}

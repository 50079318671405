
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class PasswordValidator {
    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
          if (!control.value) {
            // if control is empty return no error
            return null as any;
          }
      
          // test the value of the control against the regexp supplied
          const valid = regex.test(control.value);
      
          // if true, return no error (no error), else return error passed in the second parameter
          return valid ? null as any : error;
        };
      }

      static passwordMatchValidator(control: AbstractControl) {
        const passwordControl = control.get('newPassword');
        const confirmPasswordControl = control.get('confirmPasswordReset');
      
        if (!passwordControl || !confirmPasswordControl) {
          return null;
        }
      
        const password: string = passwordControl.value;
        const confirmPasswordReset: string = confirmPasswordControl.value;
      
        if (password !== confirmPasswordReset) {
          confirmPasswordControl.setErrors({ NoPasswordMatch: true });
        } else {
          confirmPasswordControl.setErrors(null);
        }
      
        return null;
      }
    }
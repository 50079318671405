import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'lib-confirm-reset',
  templateUrl: './confirm-reset.component.html',
  styleUrls: ['./confirm-reset.component.css']
})
export class ConfirmResetComponent {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private logger: NGXLogger) { }

  submitted = true;

  ngOnInit() {
    const resetId = this.activatedRoute.snapshot.paramMap.get('id');
    this.logger.log('Reset ID:', resetId);

    if (resetId !== null) {
      const existingResetId = localStorage.getItem('resetId');
      if (existingResetId !== resetId) {
        localStorage.setItem('resetId', resetId);
      }
    }

    setTimeout(() => {
      this.router.navigate(['/resetpassword']);
    }, 3000);  // 3 seconds
  }
}

import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { HttpClient } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { AuthGuard } from 'projects/petszel-shelter/src/app/service/authentication-guard.service';
import { SharedLibraryService } from '../services/shared.config';
import { AuthenticationGuardService } from 'projects/petszel-staff/src/app/service/authentication-guard.service';
import { AuthGuardService } from 'projects/petszel-owner/src/app/service/authentication-guard.service';
import { domainValidatorForCredentials } from '../services/username-validator-staff';
import { DeviceDetectorService } from 'ngx-device-detector';
import { appConfig as appConfigOwner } from 'projects/petszel-owner/src/owner.config';
import { appConfig as appConfigShelter } from 'projects/petszel-shelter/src/shelter.config';
@Component({
  selector: 'lib-petszel-login',
  templateUrl: './petszel-login.component.html',
  styleUrls: ['./petszel-login.component.css'],
})
export class PetszelLoginComponent {
  loginForm!: FormGroup;
  submitted = false;
  isOnboardingStarted = false;
  credentialType: string | undefined;
  badCredentials = false;
  @Input() newUser!: any[];
  @Input() user!: any[];
  @Input() currentApplication!: string;
  showErrorBanner = false;
  targetUrl: string | null = null;
  isOwnerApplication: boolean = false;
  isShelterApplication: boolean = false;
  hidePassword = true;
  isMobile!: boolean;
  environmentConfig: any;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private formBuilder: FormBuilder,
    private router: Router,
    private authGuard: AuthGuard,
    private staffAuthGuard: AuthenticationGuardService,
    private ownerAuthGuard: AuthGuardService,
    public sharedLibraryService: SharedLibraryService,
    private deviceDetectorService: DeviceDetectorService,
    ) {}

  ngOnInit(): void {
    if (this.currentApplication === 'owner') {
      this.environmentConfig = appConfigOwner;
      this.isOwnerApplication = true;
      this.isShelterApplication = false;
    } else if (this.currentApplication === 'shelter') {
      this.environmentConfig = appConfigShelter;
      this.isShelterApplication = true;
      this.isOwnerApplication = false;
    } else {
    }

    this.isMobile = this.deviceDetectorService.isMobile();
    const getSharedLibraryConfig: { applicationId: string } =
      this.sharedLibraryService.getSharedLibraryConfig();
    const applicationId: string = getSharedLibraryConfig.applicationId;
    this.loginForm = this.formBuilder.group({
      applicationId: [applicationId],
      username: [
        '',
        [
          Validators.compose([
            Validators.email,
            Validators.required,
            domainValidatorForCredentials('petszel.com', [
              'PetszelStaff',
              'PetszelAdmin',
            ]),
          ]),
        ],
      ],
      password: [
        '',
        [
          Validators.compose([
            Validators.required,
            Validators.pattern('(?=.*[0-9]).{7,}'),
          ]),
        ],
      ],
      credentialType: [''],
    });
    this.isOwnerApplication = applicationId === 'owner';
  }

  togglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
    const passwordInput = document.getElementById(
      'password'
    ) as HTMLInputElement;
    passwordInput.type = this.hidePassword ? 'password' : 'text';
  }

  makePostRequest() {
    const url =
      this.sharedLibraryService.getSharedLibraryConfig().apiUrl + 'Authorize';
    const applicationId =
      this.sharedLibraryService.getSharedLibraryConfig().applicationId;
    this.logger.log(applicationId);
    const data = { ...this.loginForm.value, applicationId };

    this.http
      .post<{ isSuccess: boolean; token: string; expiration: string }>(
        url,
        data
      )
      .subscribe(
        (response) => {
          if (
            response.isSuccess &&
            response.token &&
            new Date(response.expiration) > new Date()
          ) {
            try {
              const decodedToken = jwt_decode<any>(response.token);

              let credentials = decodedToken['petszel-credentialtype'];
              if (!Array.isArray(credentials)) {
                credentials = [credentials];
              }

              // Define roles in order of priority
              const rolePriority = [
                'PetszelAdmin',
                'PetszelStaff',
                'PetszelConnectShelterAdmin',
                'PetszelConnectShelterSiteAdmin',
                'PetszelConnectShelterStaff',
                'PetszelConnectClient',
              ];

              for (let role of rolePriority) {
                if (credentials.includes(role)) {
                  this.handleCredential(role, applicationId, response.token);
                  return;
                }
              }
            } catch (error) {
              this.handleBadCredentials();
            }
            // If the user is not logged in, store the target URL
            if (!localStorage.getItem('token') && this.targetUrl) {
              localStorage.setItem('targetUrl', this.targetUrl);
            }
          } else {
            this.handleBadCredentials();
          }

          // Log authentication events
          if (!this.badCredentials) {
            this.logger.log('User login successful', {
              username: this.loginForm.value.username,
            });
          } else {
            this.logger.error('User login failed', {
              username: this.loginForm.value.username,
            });
          }
        },
        (error) => {
          this.logger.error(error);
          if (error.status === 400 || 503) {
            this.handleBadCredentials();
          }
        }
      );
  }

  handleCredential(credential: string, applicationId: string, token: string) {
    switch (credential) {
      case 'PetszelStaff':
      case 'PetszelAdmin':
        this.staffAuthGuard.redirectCallback(credential);
        localStorage.setItem('token', token);
        break;
      case 'PetszelConnectShelterAdmin':
      case 'PetszelConnectShelterSiteAdmin':
      case 'PetszelConnectShelterStaff':
        if (applicationId === 'shelter') {
          this.authGuard.redirectCallback(credential);
          localStorage.setItem('token', token);
        } else {
          this.authGuard.redirectCallback(credential);
        }
        break;
      case 'PetszelConnectClient':
        if (
          this.ownerAuthGuard.redirectRoute === '/owner-dashboard' &&
          applicationId === 'owner'
        ) {
          this.ownerAuthGuard.redirectCallback(credential);
          localStorage.setItem('token', token);
        } else {
          this.badCredentials = true;
          localStorage.removeItem('token');
          this.router.navigateByUrl('/');
        }
        break;
      default:
        this.badCredentials = true;
        localStorage.removeItem('token');
        this.router.navigateByUrl('/');
    }
  }

  handleBadCredentials() {
    this.badCredentials = true;
    this.showErrorBanner = true;
    localStorage.removeItem('token');
    // this.router.navigateByUrl('/');
  }

  logout() {
    localStorage.removeItem('token'); // Clear the token from client-side storage
    this.router.navigateByUrl('/'); // Redirect to the home URL

    // Log user logout event
    this.logger.log('User logout', { username: this.loginForm.value.username });
  }

  get login() {
    return this.loginForm.controls;
  }

  onLogin() {
    this.showErrorBanner = false;
    if (this.loginForm?.invalid) {
      return;
    }

    // Store the target URL before making the POST request
    this.targetUrl = localStorage.getItem('targetUrl');

    this.makePostRequest();
    this.submitted = true;
    this.logger.log(this.submitted);
  }

  clearUsernameError() {
    this.loginForm.controls['username'].setErrors(null);
  }

  clearPasswordError() {
    this.loginForm.controls['password'].setErrors(null);
  }
}

import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { OwnerData } from 'projects/petszel-shared/src/public-api';

@Injectable({
    providedIn: 'root',
  })
  export class OwnerDataService {
    private ownerData: OwnerData | undefined;
    private ownerAddress: any[] = [];
  
    constructor(private logger: NGXLogger) {}
  
    setOwnerData(ownerData: OwnerData) {
        this.ownerData = ownerData;
      }
    
      getOwnerData(): OwnerData | undefined {
        return this.ownerData;
      }
  
    setOwnerAddress(ownerAddress: any[]) {
      this.ownerAddress = ownerAddress;
      this.logger.log('Owner Address set:', this.ownerAddress); // Add this line
    }
  
    getOwnerAddress(): any[] {
      this.logger.log('Getting Owner Address:', this.ownerAddress); // Add this line
      return this.ownerAddress;
    }
  }
  

import { Component, HostListener, Input, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'lib-petszel-map',
  templateUrl: './petszel-map.component.html',
  styleUrls: ['./petszel-map.component.css']
})
export class PetszelMapComponent {
  @Input() latitude: number = 0;
  @Input() longitude: number = 0;
  @Input() mapZoom: number = 3
  @Input() shelterMarker: any;
  @Input() markers: any;
  @Input() places!: any[];
  @ViewChild('smallMap') smallMap: GoogleMap | undefined;
  @ViewChild('largeMap') largeMap: GoogleMap | undefined;
  @Input() customMapOptions: google.maps.MapOptions | undefined;

  constructor(private logger: NGXLogger){}

    @ViewChild(GoogleMap) map: google.maps.Map | undefined;
    isSmallScreen: boolean = window.innerWidth < 768;

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
      this.isSmallScreen = window.innerWidth < 768;
    }
  center: google.maps.LatLngLiteral = { lat: 39, lng: -95};
  zoom = 3;
  display: google.maps.LatLngLiteral = { lat: 39, lng: -95};

  ngOnInit(): void {
    this.center = { lat: this.latitude, lng: this.longitude};
    this.zoom = this.mapZoom;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mapHeight'] && changes['mapHeight'].currentValue) {
      this.logger.log('PetszelMapComponent mapHeight:', changes['mapHeight'].currentValue);
    }
  }
  
  mapOptions: google.maps.MapOptions = {
    mapTypeId: 'roadmap', // Set map type to roadmap
    streetViewControl: false, // Disable street view control
    zoomControl: true,
    gestureHandling: 'none'
  };

  ngAfterViewInit(): void {
    
    const defaultMapOptions: google.maps.MapOptions = {
      mapTypeId: 'roadmap',
      streetViewControl: false,
      zoomControl: true,
    };
  
    const mapOptions = this.customMapOptions ? { ...defaultMapOptions, ...this.customMapOptions } : defaultMapOptions;
  
    if (this.smallMap?.googleMap) {
      this.smallMap.googleMap.setOptions(mapOptions);
    }
  
    if (this.largeMap?.googleMap) {
      this.largeMap.googleMap.setOptions(mapOptions);
    }
  }
  
}

import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastsService {

  toasts: any[] = [];

  constructor() { }

  show(textOrTpl: string | TemplateRef<any>, tempOptions: any = {}) {
    const options = {
      delay: 2000,
      ...tempOptions
    };
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appConfig } from '../shelter.config';
import { SharedLibraryService, sharedLibraryConfigProvider } from 'projects/petszel-shared/src/public-api';
import { AuthGuard } from "./service/authentication-guard.service";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../app/home/home.module').then((m) => m.HomeModule),
    data: { bodyClass: 'gradient' },
  },
  {
    path: '',
    loadChildren: () => import('./layout/main-layout/main-layout.module').then((m) => m.MainLayoutModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./layout/onboarding-layout/onboarding-layout.module').then((m) => m.OnboardingLayoutModule),
    data: { bodyClass: 'orange' },

  },
  {
    path: 'shelter-dashboard',
    loadChildren: () => import('./layout/onboarding-layout/onboarding-layout.module').then((m) => m.OnboardingLayoutModule),
    data: { bodyClass: 'orange' },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: 'appConfig',
      useValue: appConfig,
    },
    sharedLibraryConfigProvider,
    SharedLibraryService,
  ],
})
export class AppRoutingModule {}



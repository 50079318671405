import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { AuthGuardService } from 'projects/petszel-owner/src/app/service/authentication-guard.service';
import { AuthGuard } from 'projects/petszel-shelter/src/app/service/authentication-guard.service';
import { AuthenticationGuardService } from 'projects/petszel-staff/src/app/service/authentication-guard.service';
import * as bootstrap from 'bootstrap';
import { LogoutService } from '../services/logout.service';
@Component({
  selector: 'lib-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.css']
})
export class FooterNavComponent implements OnInit {
  @Input() navItems!: any[];
  @Input() item: any;
  @Input() navItems2!: any[];
  @Input() navItems3!: any[];
  @Input() shelterLogoSrc!: string;
  @Input() size!: string;
  @Input() brandingDays!: number;
  @Input() daysSinceAdoption!: number;
  @Input() isApplication1!: boolean;
  @ViewChild('navbarNav', { static: false }) menuElement!: ElementRef;
  @Output() actionTriggered = new EventEmitter<string>();
  isMenuOpen = false;
  isLoggedIn: boolean = false;
  isOffcanvasOpen = false;
  isNavbarCollapsed = true;
  @Input() logo: any;

  constructor(
    private logoutService: LogoutService,
    private authGuard: AuthGuard,
    private authGuardService: AuthGuardService,
    private authenticationGuardService: AuthenticationGuardService,
  ) {
  }

  ngOnInit() {
    this.isLoggedIn = this.checkIsLoggedIn();

    this.logoutService.logoutAfterDelay(4000 * 60 * 60); // Logout after 4 hours
  }

  performAction(action: string) {
    this.actionTriggered.emit(action);

    switch (action) {
      case 'openOffcanvas':
        this.openOffcanvas();
        break;
    }
  }

  performItemClick(item: any, event: MouseEvent) {
    if (item.action) {
      // If there is a specific action, perform it
      this.performActionSm(item.action);
    } else {
      // Close the hamburger menu
      this.isMenuOpen = false;

    }
    event.preventDefault();
  }

  performActionSm(action: string) {

    switch (action) {
      case 'openOffcanvas':
        this.openOffcanvasSm();
        break;
    }
  }

  openOffcanvasSm() {
    const offcanvasElement = document.getElementById(
      'emergencyOffcanvasBottom'
    );

    if (offcanvasElement instanceof Element) {
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvasElement);
      bsOffcanvas.show();
    } else {
      console.error('Offcanvas element not found');
    }
  }

  openOffcanvas() {
    const offcanvasElement = document.getElementById('emergencyOffcanvasRight');
    if (offcanvasElement instanceof Element) {
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvasElement);
      bsOffcanvas.show();
    } else {
      console.error('Offcanvas element not found');
    }
  }

  checkIsLoggedIn(): boolean {
    return (
      this.authGuardService.isLoggedIn() ||
      this.authGuard.isLoggedIn() ||
      this.authenticationGuardService.isLoggedIn()
    );
  }

  closeOffcanvas() {
    this.isOffcanvasOpen = false;
  }
}

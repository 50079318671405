import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalPartnerSearchService } from '../../../../petszel-staff/src/app/service/local-partner-search.service';
@Component({
  selector: 'lib-provider-search-list-result-owner',
  templateUrl: './provider-search-list-result-owner.component.html',
  styleUrls: ['./provider-search-list-result-owner.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderSearchListResultOwnerComponent {
  @Input() categories: any[] = [];
  @Input() partners: any[] = [];
  @Input() selectedPartners: any;
  @Input() validationErrors: any;
  @Input() ownerItem: boolean = false;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemUnselected: EventEmitter<any> = new EventEmitter<any>();
  @Output() addPartners: EventEmitter<any> = new EventEmitter<any>();
  @Input() isLoading!: boolean;
  @Input() owner!: any;
  @Input() selectedItem: any;
  @Input() isItemSelected!: boolean;
  @Input() distanceFromParent?: number;
  isStaff = false;
  @Input() shelterCategories: any[] = [];
  @Input() set selectedCategory(value: string) {
    if (value) {
      this.reorderPartnersByCategory(value);
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private localPartnerSearchService: LocalPartnerSearchService
  ) {}

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.fetchShelterCategories();
  }

  addPartnersClicked() {
    this.addPartners.emit(this.selectedPartners);
  }

  fetchShelterCategories() {
    this.localPartnerSearchService
      .getShelterCategory()
      .subscribe((data: any) => {
        this.shelterCategories = data;
        this.cdr.markForCheck(); // Trigger change detection
      });
  }

  goBackToListView() {
    // Clear the selectedItem to return to the list view
    this.selectedItem = null;
    this.isItemSelected = false; // Set the flag to false
    this.itemUnselected.emit(); // Emit the event to notify the parent component
  }

  listItemSelected(partner: any) {
    this.isItemSelected = true;
    const partnerCategories = this.getCategoriesForPartner(partner);
    this.selectedItem = { ...partner, categories: partnerCategories };
    this.itemSelected.emit({
      selected: this.isItemSelected,
      distance: partner.distance,
      shelterCategories: partnerCategories,
    });
    this.cdr.detectChanges();
  }

  resetSelectedItem() {
    this.isItemSelected = false;

    this.selectedItem = false;
  }

  listItemUnselected(partner: any) {
    if (this.selectedItem === partner) {
      this.selectedItem = null;
    }
    this.itemUnselected.emit(partner);
  }

  isPartnerFavorited(partner: any): boolean {
    const storedGooglePlacesIdsString = localStorage.getItem('googlePlacesIds');
    let storedGooglePlacesIds: string[] = [];
    if (storedGooglePlacesIdsString) {
      storedGooglePlacesIds = JSON.parse(storedGooglePlacesIdsString);
    }
    this.cdr.markForCheck(); // Trigger change detection
    return storedGooglePlacesIds.includes(partner.place_id);
  }

  private isPartnerFavoritedAndCategoryMatch(
    partner: any,
    category: string
  ): boolean {
    return (
      this.isPartnerFavorited(partner) &&
      this.shelterCategories.some(
        (shelterCategory) =>
          shelterCategory.name === category &&
          shelterCategory.googlePlacesId === partner.place_id
      )
    );
  }

  getCategoriesForPartner(partner: any): any[] {
    return this.shelterCategories
      .filter((category) => category.googlePlacesId === partner.place_id)
      .map((category) => ({
        ...category,
        category: this.transformCategoryName(category.category),
        secondaryCategories: category.secondaryCategories.map(
          this.transformCategoryName
        ),
      }));
  }

  private reorderPartnersByCategory(category: string): void {
    const favoritedPartners = this.partners.filter((partner: any) =>
      this.isPartnerFavoritedAndCategoryMatch(partner, category)
    );
    const nonFavoritedPartners = this.partners.filter(
      (partner: any) =>
        !this.isPartnerFavoritedAndCategoryMatch(partner, category)
    );

    // Concatenate favorited partners at the beginning
    this.partners = [...favoritedPartners, ...nonFavoritedPartners];
    this.cdr.markForCheck();
  }

  transformCategoryName(categoryName: string): string {
    const nameMappings: { [key: string]: string } = {
      SpecialtyVeterinaryPractice: 'Veterinary Specialty',
      VeterinaryPractice: 'Veterinary',
      EmergencyVeterinaryPractice: 'Veterinary ER',
      PetSupplies: 'Pet Supplies',
      // add other mappings as needed
    };

    return nameMappings[categoryName] || categoryName; // Default to original name if not found in mappings
  }
}

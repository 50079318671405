import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges 
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
interface Place {
  googlePlacesId?: string;
  name: string;
  rating?: number;
  formatted_address?: string;
  formatted_phone_number?: string;
  website?: string;
  showWeekdays?: boolean;
  geometry?: any;
  current_opening_hours?: CurrentOpeningHours;
}
interface OpeningHours {
  date: string;
  day: number;
  time: string;
}

interface Period {
  open: OpeningHours;
  close?: OpeningHours;
}

interface CurrentOpeningHours {
  open_now?: boolean;
  weekday_text?: string[];
  periods?: Period[];
}
@Component({
  selector: 'lib-provider-search-result-item',
  templateUrl: './provider-search-result-item.component.html',
  styleUrls: ['./provider-search-result-item.component.css'],
})
export class ProviderSearchResultItemComponent {
  @Input() partner: any;
  @Output() isStaff!: boolean;
  @Output() listItemUnselected: EventEmitter<any> = new EventEmitter<any>();
  @Output() listItemSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() isFavorited!: boolean;
  showWeekdays: boolean = false;
  @Input() categories: any[] = [];  
  selectedCategory: any;
  showPartnerSelectDiv = false;
  categoryIcons: { [key: string]: string } = {
    'Veterinary': 'assets/images/vet-icon.png', 
    'Veterinary ER': 'assets/images/vet-icon2.png',
    'Veterinary Specialty': 'assets/images/vet-icon.png',
    'Pet Supplies': 'assets/images/pet-supplies-icon.png',
    'Boarding': 'assets/images/boarding-icon.png',
    'Grooming': 'assets/images/grooming-icon.png',
    'Training': 'assets/images/training-icon.png',
  };
  
  constructor(private cdr: ChangeDetectorRef, private logger: NGXLogger) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['categories']) {
    }
  }

  getIconForCategory(category?: string): string | undefined {
    // Log the category and resulting path for debugging
    return this.categoryIcons[category!];
  }
  
  ngOnInit(): void {
    this.cdr.detectChanges();
  }

  cardClicked(partner: any) {
    this.listItemSelected.emit(partner);
  }

  togglePartnerSelect() {
    this.showPartnerSelectDiv = !this.showPartnerSelectDiv;
    var p = this.partner;

    this.listItemSelected.emit(p);
  }

  getStarsArray(): number[] {
    const rating = +this.partner.rating;
    if (!isNaN(rating) && isFinite(rating)) {
      return new Array(Math.round(rating)).fill(1);
    } else {
      return [];
    }
  }

  getOpeningTimeForTomorrow(): string | null {
    const today = new Date().getDay();
    const tomorrow = (today + 1) % 7;

    // Use optional chaining to safely access nested properties
    const tomorrowPeriods = this.partner?.opening_hours?.periods?.find(
      (period: any) => period.open?.day === tomorrow
    );

    if (tomorrowPeriods?.open) {
      const time = `${tomorrowPeriods.open.hours}${
        tomorrowPeriods.open.minutes < 10
          ? '0' + tomorrowPeriods.open.minutes
          : tomorrowPeriods.open.minutes
      }`;
      return this.convertToStandardTime(time);
    }

    return null;
  }

  getClosingTimeForToday(): string | null {
    const today = new Date().getDay();

    // Use optional chaining to safely access nested properties
    const todayPeriods = this.partner?.opening_hours?.periods?.find(
      (period: any) => period.close?.day === today
    );

    if (todayPeriods?.close) {
      const time = `${todayPeriods.close.hours}${
        todayPeriods.close.minutes < 10
          ? '0' + todayPeriods.close.minutes
          : todayPeriods.close.minutes
      }`;
      return this.convertToStandardTime(time);
    }

    return null;
  }

  convertToStandardTime(time: string): string {
    const hour = parseInt(time.substring(0, 2));
    const minutes = time.substring(2);

    if (hour === 0) {
      return `12:${minutes} AM`;
    } else if (hour < 12) {
      return `${hour}:${minutes} AM`;
    } else if (hour === 12) {
      return `12:${minutes} PM`;
    } else {
      return `${hour - 12}:${minutes} PM`;
    }
  }

  getClosingTime(place: Place): string {
    const today = new Date().getDay();

    const todayPeriod = place?.current_opening_hours?.periods?.find(
      (period: Period) => period.close?.day === today
    );

    if (todayPeriod?.close) {
      const time = `${todayPeriod.close.time}`;
      const closingTime = this.convertToStandardTime(time);
      return closingTime;
    }

    return '';
  }

  getOpeningTime(place: Place): string {
    const today = new Date().getDay();
    const tomorrow = (today + 1) % 7;

    const tomorrowPeriod = place.current_opening_hours?.periods?.find(
      (period: Period) => period.open.day === tomorrow
    );

    return tomorrowPeriod
      ? this.convertToStandardTime(tomorrowPeriod.open.time)
      : '';
  }

  toggleWeekdaySelection(place: Place) {
    if (place.showWeekdays !== undefined) {
      place.showWeekdays = !place.showWeekdays;
    } else {
      place.showWeekdays = true; // default value if it was never set
    }
    this.cdr.detectChanges();
  }

  toggleWeekdays() {
    this.showWeekdays = !this.showWeekdays;
    this.cdr.detectChanges();
  }

  isRatingValid(): boolean {
    return (
      typeof this.partner.rating === 'number' && !isNaN(this.partner.rating)
    );
  }

  togglePartnerUnselect() {
    this.showPartnerSelectDiv = !this.showPartnerSelectDiv;
    var p = this.partner;
    this.listItemUnselected.emit(p);
  }

  itemSelected(partner: any) {
    // Here, you can call your method to get place details and photos
    this.getPlaceDetails(partner.place_id);
    this.listItemSelected.emit(partner);
  }

  private getPlaceDetails(placeId: string): void {
    const request: google.maps.places.PlaceDetailsRequest = {
      placeId: placeId,
      fields: ['photos'],
    };

    // Use the Google Places API directly
    const placesService = new google.maps.places.PlacesService(
      document.createElement('div')
    );

    placesService.getDetails(
      request,
      (
        place: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          const photos = place.photos;
          // Inside getPlaceDetails method
          if (photos && photos.length > 0) {
            // Display the first photo in your HTML
            this.partner.photoUrl = photos[0].getUrl();
            this.logger.log('Photo URL:', this.partner.photoUrl);
          }
        }
      }
    );
  }
}

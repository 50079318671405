import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationError, Router, RouterStateSnapshot } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuardService {
  redirectRoute: string = '/staff-dashboard';
  badCredentials = false;
  private targetUrl: string | null = null;

  constructor(private router: Router, private logger: NGXLogger) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationError) {
        console.error('Navigation error:', event.error);
        this.logger.error('Navigation error:', event.error);
      }
    });
  }

  isLoggedIn(redirect: boolean = false): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwt_decode<any>(token);
        const credentialType = decodedToken['petszel-credentialtype'];
        if (Array.isArray(credentialType)) {
          return (
            credentialType.includes('PetszelStaff') ||
            credentialType.includes('PetszelAdmin')
          );
        } else {
          return (
            credentialType === 'PetszelStaff' ||
            credentialType === 'PetszelAdmin'
          );
        }
      } catch (error) {
        return false;
      }
    } else if (redirect) {
      // If there's no token and the redirect parameter is true, perform the redirect
      const savedTargetUrl = this.getTargetUrl(); // Use your custom method
      if (savedTargetUrl) {
        // Redirect the user to the saved target URL
        window.location.href = savedTargetUrl;
      }
    }
    return false;
  }  

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isLoggedIn()) {
      return true; // User is logged in, allow access to the route
    } else {
      // User is not logged in, save the target URL with a query parameter
      this.setTargetUrl(state.url);
      this.router.navigate(['/'], {
        queryParams: { redirect: state.url },
      });
      return false;
    }
  }

  redirectCallback(credentialType: any) {
    // Check if the credentialType is a string and matches one of the valid types
    const isValidString =
      typeof credentialType === 'string' &&
      (credentialType === 'PetszelStaff' || credentialType === 'PetszelAdmin');
  
    // Check if the credentialType is an array and contains at least one of the valid types
    const isValidArray =
      Array.isArray(credentialType) &&
      (credentialType.includes('PetszelStaff') || credentialType.includes('PetszelAdmin'));
  
      if (isValidString || isValidArray) {
        const savedTargetUrl = this.getTargetUrl(); // Get the saved target URL
        const targetUrl = savedTargetUrl || this.redirectRoute; // Use savedTargetUrl or fallback to redirectRoute
    
        this.router.navigateByUrl(targetUrl).then((success) => {
          if (!success) {
            this.logger.error('Navigation failed');
          }
        });    
    }
  }  

  getTargetUrl(): string | null {
    return localStorage.getItem('savedTargetUrl');
  }

  setTargetUrl(url: string): void {
    this.targetUrl = url;
  }

    // In AuthGuardService
    logoutAndRedirect(): Observable<void> {
      // Perform the logout logic here, e.g., clearing token, redirecting to login page
    localStorage.removeItem('token'); // Remove the token
    this.router.navigateByUrl('/'); // Redirect to the login page
    return of(undefined);
  }
}  



<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  [autohide]="true"
  [delay]="toast.delay || 2000"
  (hidden)="toastService.remove(toast)"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>
    <div class="d-flex gap-2">
      <i class="bi bi-check" *ngIf="toast.classname === 'bg-success'"></i>
      <i class="bi bi-x" *ngIf="toast.classname === 'bg-danger'"></i>
      <i class="bi bi-info-circle" *ngIf="toast.classname === 'bg-info'"></i>

      <span>{{ toast.textOrTpl }}</span>
    </div>
  </ng-template>
</ngb-toast>


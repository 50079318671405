<div class="container-login">
  <div class="row box-border-image">
    <div class="col box-border-image">
      <img
        src="../../assets/images/dog-beach-vertical.jpg"
        class="box-border-image d-md-block d-none"
        alt="Couple sitting on the beach with the dog"
        *ngIf="isOwnerApplication"
      />
      <img
        src="../../assets/images/dog-beach-horizontal.jpg"
        class="box-border-image-alt d-md-none"
        alt="Couple sitting on the beach with the dog"
        *ngIf="isOwnerApplication"
      />
      <img
        src="../../assets/images/dog-ball-vertical.jpg"
        class="box-border-image d-md-block d-none"
        alt="Couple sitting on the beach with the dog"
        *ngIf="isShelterApplication"
      />
      <img
        src="../../assets/images/dog-ball-horiztonal.jpg"
        class="box-border-image-alt d-md-none"
        alt="Couple sitting on the beach with the dog"
        *ngIf="isShelterApplication"
      />
      <img
        src="../../assets/images/dog-vertical.jpg"
        class="box-border-image d-md-block d-none"
        alt="Image description"
        *ngIf="!isOwnerApplication && !isShelterApplication"
      />
      <img
        src="../../assets/images/dog-horizontal.jpg"
        class="box-border-image-alt d-md-none"
        alt="Alternative Image description"
        *ngIf="!isOwnerApplication && !isShelterApplication"
      />
      <div class="image-text" *ngIf="isShelterApplication || isOwnerApplication">
        <div *ngIf="isShelterApplication">
          Please visit <a href="https://www.petszel.com">Petszel</a> to learn more and sign up for future access.
        </div>
        
        <div *ngIf="isOwnerApplication">
          Are you a shelter, rescue, or pet care provider? Please visit <a href="https://www.petszel.com">Petszel</a> to learn more.
        </div>
      </div>
    </div>
  </div>
  <div class="row box-border">
    <router-outlet></router-outlet>
    <form class="col" [formGroup]="loginForm" (ngSubmit)="onLogin()">
      <div class="started">
        <img class="logo mb-3" src="../../assets/images/petszelLogo.png" />
        <h3 class="font mb-4">
          {{ isOwnerApplication ? 'Pet Parent Login' : (isShelterApplication ? 'Shelter & Rescue Login' : 'Staff Login') }}
        </h3>        
        <div class="form-group">
          <div class="row">
            <div class="col-sm-7 col">
              <label for="username" class="label">Username</label>
            </div>
            <div
              class="col-sm-5 col required d-flex align-items-end justify-content-end"
            >
              <div class="danger">*</div>
            </div>
          </div>
          <input
            formControlName="username"
            type="email"
            class="form-control"
            id="username"
            autocomplete="email"
            placeholder="name@example.com"
            required
            (input)="clearUsernameError()"
          />
          <div class="required d-flex align-items-end">
            <div
              *ngIf="
                (submitted &&
                  badCredentials &&
                  loginForm.controls['username'].errors &&
                  !isOnboardingStarted) ||
                (loginForm.controls['username'].errors &&
                  !isOnboardingStarted &&
                  (loginForm.controls['username'].dirty ||
                    loginForm.controls['username'].touched))
              "
              class="danger text-left"
            >
              Invalid Entry - Please re-enter field or fill it out to proceed
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row mt-3">
            <div class="col-sm-7 col">
              <label for="password" class="label">Password</label>
            </div>
            <div
              class="col-sm-5 col required d-flex align-items-end justify-content-end"
            >
              <div class="danger">*</div>
            </div>
          </div>
          <div class="input-group">

          <input
            formControlName="password"
            type="password"
            class="form-control"
            id="password"
            placeholder="Password"
            required
            (input)="clearPasswordError()"
          />
          <button class="btn btn-outline-secondary bg-white" type="button" (click)="togglePasswordVisibility()">
            <i class="bi" [class.bi-eye-slash]="hidePassword" [class.bi-eye]="!hidePassword"></i>
        </button>
        </div>
          <div class="required d-flex align-items-end">
            <div
              *ngIf="
                (submitted &&
                  badCredentials &&
                  loginForm.controls['password'].errors &&
                  !isOnboardingStarted) ||
                (loginForm.controls['password'].errors &&
                  !isOnboardingStarted &&
                  (loginForm.controls['password'].dirty ||
                    loginForm.controls['password'].touched))
              "
              class="danger"
            >
              Invalid credentials. Please check your username and password.
            </div>
          </div>
          <div class="required d-flex align-items-end">
            <div *ngIf="submitted && badCredentials" class="danger">
              The username or password you entered was incorrect.
            </div>
          </div>
        </div>
        <div class="w-75">
          <a routerLink="/passwordreset/email" class="forgot-password"
            >Forgot Password</a
          >
        </div>
        <input type="hidden" formControlName="applicationId" value="app_id" />
        <input class="btn btn-primary" type="submit" value="Login" />
        <!-- <div *ngIf="isOwnerApplication">
            <a class="new-user" href="https://www.petszel.com">New User</a>
        </div> -->
        <div *ngIf="isShelterApplication" class="text-center">
          <div class="span-btn-customer" *ngFor="let user of newUser">
            <span class="new-customer">New customer?</span>
            <button
              class="btn btn-outline-primary"
              [routerLink]="user.routerLink"
              type="button"
            >
            Sign-up Now
            </button>
          </div>
          <div class="pet-margin">
            <span class="text-center pet-parent">Pet parents please go to <a class="app-petszel" href="https://app.petszel.com">app.petszel.com</a> to login.</span>
          </div>
        </div>
        
      </div>
    </form>
  </div>
</div>
<div class="row">
  <div *ngIf="showErrorBanner" class="alert alert-danger mt-3 text-center">
    An error has occurred, please try again. If the issue persists, please
    contact <a href="mailto:support@petszel.com">support&#64;petszel.com</a>.
  </div>
</div>

<div class="petszel-partner-search-list" >  
  <nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <button
        class="petszel-nav-link nav-link active"
        id="nav-home-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-home"
        type="button"
        role="tab"
        aria-controls="nav-home"
        aria-selected="true"
      >
        All
      </button>
      <button
        class="nav-link"
        id="nav-profile-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-profile"
        type="button"
        role="tab"
        aria-controls="nav-profile"
        aria-selected="false"
      >
        Selected Properties ({{ selectedPartners.length }})
      </button>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div
      class="partner-selection-list tab-pane fade show active"
      id="nav-home"
      role="tabpanel"
      aria-labelledby="nav-home-tab"
      tabindex="0"
    >
    
  <div class="row">
    <div class="col-md-12">
      <ng-template #staff>
          <lib-petszel-partner-search-result-list-item
              *ngFor="let partner of partners"
              [partner]="partner"
              [categories]="categories"
              (listItemSelected)="listItemSelected($event)"
              (listItemUnselected)="listItemUnselected($event)"
          ></lib-petszel-partner-search-result-list-item>
      </ng-template>
      <ng-container *ngIf="owner1; else staff">
        <lib-provider-search-result-item
        *ngFor="let partner of partners"
        [partner]="partner"
        [categories]="categories"
        (listItemSelected)="listItemSelected($event)"
        (listItemUnselected)="listItemUnselected($event)"
    ></lib-provider-search-result-item>
      </ng-container>
    </div>
  </div>
    </div>
    <div
      class="partner-selection-list tab-pane fade"
      id="nav-profile"
      role="tabpanel"
      aria-labelledby="nav-profile-tab"
      tabindex="0"
    >
    <div *ngIf="validationErrors" class="row pt-2">
      <div class="col-md-12">
        <ul>
          <li *ngFor="let error of validationErrors" class=" text-danger">{{ error.message }}</li>
        </ul>
      </div>
    </div>
      <div class="row">
        <div class="col-md-4">
          <button *ngIf="loading" class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
          <lib-primary-btn *ngIf="!loading && selectedPartners.length > 0 "
            primaryBtn="primaryBtnSm"
            (click)="addPartnersClicked()"
            buttonText="Add ({{ selectedPartners.length }})"
          ></lib-primary-btn>
        </div>
      </div>

      <br />
      <div class="row">
        <div class="col-md-12">
          <ng-template #providerSelected>
            <lib-petszel-partner-search-selected-item
              *ngFor="let sp of selectedPartners"
              [selectedPartner]="sp"
              [categories]="categories">
            </lib-petszel-partner-search-selected-item>
          </ng-template>
          <ng-container *ngIf="owner; else providerSelected">
            <lib-provider-selected
              *ngFor="let sp of selectedPartners"
              [selectedPartner]="sp"
              [owner]="owner"
              [categories]="categories"
            ></lib-provider-selected>
          </ng-container>
        </div>
      </div>
      
    </div>
  </div>
</div>

import { environment as betaEnvironment } from './environments/environment.beta';
import { environment as devEnvironment } from './environments/environment.dev';
import { environment as prodEnvironment } from './environments/environment.prod';
import { environment as baseEnvironment } from './environments/environment';

export const appConfig = {
  applicationId: 'shelter',
  beta: {
    apiUrl: betaEnvironment.apiUrl,
    beta: betaEnvironment.beta,
  },
  dev: {
    apiUrl: devEnvironment.apiUrl,
    development: devEnvironment.development,
  },
  prod: {
    apiUrl: prodEnvironment.apiUrl,
    production: prodEnvironment.production,
  },
    apiUrl: baseEnvironment.apiUrl,
};


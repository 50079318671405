import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { confirmNewPasswordValidator } from "./confirm-new-password.validator";
import { ToastsService } from "../../../../../petszel-shared/src/lib/services/toasts.service";
import { Subscription } from "rxjs";
import { ShelterService } from "../../service/shelter.service";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  form!: FormGroup;
  isLoading: boolean = false;
  incorrectPassword: boolean = false;

  managePassword!: Subscription;

  constructor(private activeModalService: NgbActiveModal,
              private shelterService: ShelterService,
              private toastsService: ToastsService) {
  }

  ngOnInit(): void {
    this.createForm();

    this.form.get('existingPassword')?.valueChanges.subscribe(() => {
      if (this.incorrectPassword) {
        this.incorrectPassword = false;
      }
    });

    this.form.get('newPassword')?.valueChanges.subscribe(() => {
      this.form.get('newPassword2')?.updateValueAndValidity();
    })
  }

  ngOnDestroy(): void {
    if (this.managePassword) { this.managePassword.unsubscribe(); }
  }

  togglePassword(input: HTMLInputElement): void {
    input.setAttribute('type', input.type === 'password' ? 'text' : 'password');
  }

  submit(): void {
    this.isLoading = true;

    const { existingPassword, newPassword } = this.form.value;
    this.managePassword = this.shelterService.changePassword({ existingPassword, newPassword})
      .subscribe({
        next: () => {
          this.isLoading = true;
          this.toastsService.show('Password changed successfully.', { classname: 'bg-success' });
          this.close();
        },
        error: err => {
          this.isLoading = false;

          if (err.error === 'Invalid password.') {
            this.incorrectPassword = true;
          } else {
            this.toastsService.show('Error while updating password.', { classname: 'bg-danger' });
          }
        }
      });
  }

  close(): void {
    this.activeModalService.close();
  }

  private createForm(): void {
    this.form = new FormGroup({
      existingPassword: new FormControl<string>('', Validators.required),
      newPassword: new FormControl<string>('',
        [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-zA-Z])(?=.*\\W)(?!.* ).{10,}$')]),
      newPassword2: new FormControl<string>('', [Validators.required, confirmNewPasswordValidator()])
    });
  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-primary-btn',
  templateUrl: './primary-btn.component.html',
  styleUrls: ['./primary-btn.component.css']
})
export class PrimaryBtnComponent {
  @Input() primaryBtn!: string;
  @Input() buttonText!: string;
  @Input() showImage!: boolean;
  @Input() imageSrc!: string;
  @Input() imageAlt!: string;
  @Input() btnImg: string = 'btnImg';
  @Input() disabled: boolean = false;

}

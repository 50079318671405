import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { ConfirmResetService } from '../services/confirm-reset.service';

@Component({
  selector: 'lib-reset-password-email',
  templateUrl: './reset-password-email.component.html',
  styleUrls: ['./reset-password-email.component.css']
})
export class ResetPasswordEmailComponent {
  formResetEmail!: FormGroup;
  submitted = false;
  resetIdExists!: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private logger: NGXLogger,
    private postReset: ConfirmResetService
  ){    this.resetIdExists = !!localStorage.getItem('resetId');
}

  ngOnInit(): void {
    this.formResetEmail = this.formBuilder.group(
      {
        username: ['', [Validators.compose([Validators.required, Validators.email])]],
      },
    );
  }

    // submit form and check if valid
    onSubmit(): void {
      this.submitted = true;
  
      if (this.formResetEmail.invalid) {
        return;
      }
      this.postReset.postResetPassword(this.formResetEmail.value).subscribe((result)=>{
        this.logger.log(result);
      });
      this.formResetEmail.reset();
      alert('Please check your email to reset your password.')
    }
}

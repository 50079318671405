<div
  class="col-md-12 d-flex flex-wrap flex-column petszel-column petszel-branding d-flex align-content-center"
>
  <img
    src="../../assets/images/petszelLogoSm.png"
    alt="Petszel Logo"
    [class.small-size]="size === 'small'"
    [class.large-size]="size === 'large'"
    class="petszelLogoSolo"
  />
</div> 
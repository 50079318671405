import { Component, EventEmitter, Input, Output, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { LocalPartnerSearchService } from '../../service/local-partner-search.service';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-petszel-partner-summary',
  templateUrl: './petszel-partner-summary.component.html',
  styleUrls: ['./petszel-partner-summary.component.css']
})
export class PetszelPartnerSummaryComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedShelter: any;
  @Input() partners: any;
  @Input() selectedCategory!: string;
  @Output() deletePartnerClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatePartnerTriggered: EventEmitter<any> = new EventEmitter<any>();

  searchTerm: string = '';
  filteredPartners: any[] = [];
  sortColumn: string = '';
  sortDirection: boolean = true;
  categories: any[] = [];
  private categorySubscription!: Subscription;
  private saveSubscription!: Subscription;

  constructor(private localPartnerSearchService: LocalPartnerSearchService,
              private logger: NGXLogger) {}

  ngOnInit(): void {
    this.filteredPartners = [...this.partners];
    this.loadCategories();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['partners']) {
      this.filteredPartners = [...this.partners];
      this.filterPartners();
    }
  }

  ngOnDestroy(): void {
    if (this.categorySubscription) {
      this.categorySubscription.unsubscribe();
    }
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
  }

  loadCategories() {
    this.categorySubscription = this.localPartnerSearchService.getPartnerCategories()
      .subscribe(
        (data: any) => {
          this.categories = data;
        },
        (error) => {
          this.logger.error('Error fetching categories:', error);
        }
      );
  }

  editPartner(partner: any) {
    partner.isEditing = true;
    partner.secondaryCategory = partner.secondaryCategories.length ? partner.secondaryCategories[0] : '';
  }

  savePartner(partner: any) {
    if (!partner.category) {
      alert('At least one category must be selected.');
      return;
    }
  
    partner.secondaryCategories = partner.secondaryCategory ? [partner.secondaryCategory] : [];
  
    this.saveSubscription = this.localPartnerSearchService.updateShelterPartner(partner.accountId, partner)
      .subscribe(
        () => {
          partner.isEditing = false;
          console.log('Partner updated:', partner);
          this.updatePartnerTriggered.emit(partner);
        },
        (error) => {
          this.logger.error('Error updating partner:', error);
        }
      );
  }    

  cancelEdit(partner: any) {
    partner.isEditing = false;
    this.filteredPartners = [...this.partners];
  }

  deletePartner(partner: any) {
    if (confirm("Are you sure want to delete " + partner.name + "?")) {
      this.localPartnerSearchService.deletePartner(partner.accountId, partner.partnerId).subscribe(
        () => {
          console.log('Partner deleted:', partner);
          this.filteredPartners = this.filteredPartners.filter(p => p.partnerId !== partner.partnerId);
          this.deletePartnerClicked.emit(partner);
        },
        (error) => {
          this.logger.error('Error deleting partner:', error);
        }
      );
    }
  }   

  filterPartners() {
    this.filteredPartners = this.partners.filter((partner: any) =>
      partner.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      partner.address.streetLine1.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      partner.address.city.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      partner.address.stateProvince.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      partner.address.zipCode.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      partner.email.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      partner.phone.phoneNumber.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      (partner.website && partner.website.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
      partner.category.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      partner.secondaryCategories.some((cat: any) => cat.toLowerCase().includes(this.searchTerm.toLowerCase()))
    );
  }

  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortColumn = column;
      this.sortDirection = true;
    }

    this.filteredPartners.sort((a: any, b: any) => {
      let aValue = this.getNestedValue(a, column);
      let bValue = this.getNestedValue(b, column);
      if (typeof aValue === 'string') aValue = aValue.toLowerCase();
      if (typeof bValue === 'string') bValue = bValue.toLowerCase();

      if (aValue < bValue) return this.sortDirection ? -1 : 1;
      if (aValue > bValue) return this.sortDirection ? 1 : -1;
      return 0;
    });
  }

  getNestedValue(obj: any, key: string) {
    return key.split('.').reduce((o, k) => (o && o[k] !== 'undefined' ? o[k] : ''), obj);
  }
}
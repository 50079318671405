import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  SimpleChanges,
  ChangeDetectionStrategy
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { AnalyticsService } from 'projects/petszel-owner/src/app/service/google-analytics.service';
import { MyProvidersService } from 'projects/petszel-owner/src/app/service/my-providers.service';
import { OwnerFamilyService } from 'projects/petszel-owner/src/app/service/owner-family.service';
import { forkJoin } from 'rxjs';


interface Place {
  googlePlacesId?: string;
  name: string;
  rating?: number;
  formatted_address?: string;
  formatted_phone_number?: string;
  website?: string;
  showWeekdays?: boolean;
  geometry?: any;
  current_opening_hours?: CurrentOpeningHours;
}
interface OpeningHours {
  date: string;
  day: number;
  time: string;
}

interface Period {
  open: OpeningHours;
  close?: OpeningHours;
}

interface CurrentOpeningHours {
  open_now?: boolean;
  weekday_text?: string[];
  periods?: Period[];
}
@Component({
  selector: 'lib-provider-selected',
  templateUrl: './provider-selected.component.html',
  styleUrls: ['./provider-selected.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ProviderSelectedComponent implements AfterViewInit {
  @Input() selectedPartner: any;
  @Input() categories: any;
  @Input() owner!: true;
  @Input() selectedItem: any;
  @Input() partner: any;
  @Input() distanceFromChild?: number;
  @Output() backToList: EventEmitter<void> = new EventEmitter<void>();
  @Output() addPartners: EventEmitter<any> = new EventEmitter<any>();
  selectedPetId: string | null = null;
  showPetSelection: boolean = false;
  showWeekdays: boolean = false;
  petsToPlacesMap: { [petId: string]: string[] } = {};
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  map!: google.maps.Map;
  petPosted: { [petId: string]: boolean } = {};
  pets: any[] = JSON.parse(localStorage.getItem('pets') || '[]');
  partners: any[] = [];
  hasSelectionChanged: boolean = false;
  areAllPetsAssociated: boolean = false;
  disableButton: boolean = true;
  @Input() shelterCategories: any[] = [];
  categoryIcons: { [key: string]: string } = {
    'Veterinary': 'assets/images/vet-icon.png',
    'Veterinary ER': 'assets/images/vet-icon2.png',
    'Veterinary Specialty': 'assets/images/vet-icon.png',
    'Pet Supplies': 'assets/images/pet-supplies-icon.png',
    'Boarding': 'assets/images/boarding-icon.png',
    'Grooming': 'assets/images/grooming-icon.png',
    'Training': 'assets/images/training-icon.png',
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private postPartner: MyProvidersService,
    private ownerFamilyService: OwnerFamilyService,
    private logger: NGXLogger,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}
  ngAfterViewInit() {
    this.initMap();
  }
  getIconForCategory(category?: string): string | undefined {
    // Log the category and resulting path for debugging
    return this.categoryIcons[category!];
  }
  initMap(): void {
    if (
      this.selectedPartner &&
      this.selectedPartner.geometry &&
      this.selectedPartner.geometry.location
    ) {
      const location = new google.maps.LatLng(
        this.selectedPartner.geometry.location.lat(),
        this.selectedPartner.geometry.location.lng()
      );

      const mapOptions: google.maps.MapOptions = {
        center: location,
        zoom: 15,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: true,
        fullscreenControl: true,
      };

      this.map = new google.maps.Map(
        this.mapContainer.nativeElement,
        mapOptions
      );

      const marker = new google.maps.Marker({
        position: location,
        map: this.map,
      });
    }
  }

  getGoogleMapsUrl(address: string): string {
    return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address);
  }

  onPetSelect() {
    this.disableButton = false;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.selectedPetId = localStorage.getItem('pet') || '';
    this.fetchPetsAndPartners();
    this.areAllPetsDisabled();
    this.cdr.detectChanges();
  }

  fetchPetsAndPartners() {
    this.cdr.detectChanges();

    this.logger.log('petsToPlacesMap:', this.petsToPlacesMap);

    // Fetch pets first
    this.ownerFamilyService.getAnimalInfo().subscribe((petsData) => {
      // Update the pets property with new data
      this.pets = petsData as any[];

      // Fetch all partners for each pet in parallel
      const partnerRequests = this.pets.map((pet) =>
        this.postPartner.getPartners(pet.id)
      );

      forkJoin(partnerRequests).subscribe((partnerResults: any[]) => {
        this.logger.log('Partner results:', partnerResults);

        for (let i = 0; i < this.pets.length; i++) {
          const petId = this.pets[i].id;
          this.petsToPlacesMap[petId] = partnerResults[i].map(
            (partner: any) => partner.googlePlacesId
          );
        }

        // Move the check here
        this.pets.forEach((pet) => {
          this.petPosted[pet.id] = this.isPetAssociatedWithSelectedPlace(
            pet.id
          );
        });

        // Check which pets are associated with the selected place
        this.pets.forEach((pet) => {
          if (this.isPetAssociatedWithSelectedPlace(pet.id)) {
            this.areAllPetsDisabled();
            this.cdr.detectChanges();

          } else {
            this.cdr.detectChanges();

          }
        });

      });
    });
  }

  isPetAssociatedWithSelectedPlace(petId: string): boolean {
    if (!this.selectedPartner || !this.selectedPartner.place_id) {
      // Handle the case where selectedPartner or place_id is undefined
      return false;
    }

    const partnerIdsForPet = this.petsToPlacesMap[petId];
    return partnerIdsForPet
      ? partnerIdsForPet.includes(this.selectedPartner.place_id)
      : false;
  }

  trackByPetId(index: number, pet: any): string {
    return pet.id;
  }

  areAllPetsDisabled(): boolean {
    const result = this.pets.every((pet) =>
      this.isPetAssociatedWithSelectedPlace(pet.id)
    );
    return result;
  }

  togglePetSelection() {
    this.showPetSelection = !this.showPetSelection;
    this.cdr.detectChanges();

    if (!this.showPetSelection) {
      this.initMap();
    }
  }

  areAllPetsAssociatedWithSelectedPlace(): boolean {
    this.areAllPetsAssociated = this.pets.every((pet) =>
      this.isPetAssociatedWithSelectedPlace(pet.id)
    );
    return this.areAllPetsAssociated;
  }

  async submitPetSelection() {
    this.logger.log('Submitting pet selection...');

    const performRequestForPet = async (petId: string) => {
      if (!this.isPetAssociatedWithSelectedPlace(petId)) {
        await this.postPartner.postPartners(petId, this.selectedPartner);

        // Update the map to include the new association
        if (this.petsToPlacesMap[petId]) {
          this.petsToPlacesMap[petId].push(this.selectedPartner.googlePlacesId);
        } else {
          this.petsToPlacesMap[petId] = [this.selectedPartner.googlePlacesId];
        }
      }
    };

    if (this.selectedPetId === 'selectAll') {
      // Loop through all pets and perform the request for each one
      for (const pet of this.pets) {
        await performRequestForPet(pet.id);
      }
    } else if (this.selectedPetId !== null) {
      // Perform the request for the selected pet, ensuring selectedPetId is not null
      await performRequestForPet(this.selectedPetId);
    } else {
      // Handle the scenario where selectedPetId is null
      console.error("selectedPetId is null");
      // Optionally, you can add more error handling logic here
    }

    // Since the associations have potentially changed, update the petPosted state
    this.pets.forEach((pet) => {
      this.petPosted[pet.id] = this.isPetAssociatedWithSelectedPlace(pet.id);
    });

    // Set the flag to true to indicate a selection change
    this.hasSelectionChanged = true;
    this.areAllPetsAssociatedWithSelectedPlace();
    this.analyticsService.trackFormSubmission(`${this.selectedPartner.name}`, 'Selected Partner');

    // Trigger change detection to update the view

    this.router.navigate(['/providers']);
    this.cdr.detectChanges();
  }

  onPetSelected(event: any): void {
    this.disableButton = false;
    this.cdr.detectChanges();
    this.selectedPetId = event.target.value;
  }

  toggleWeekdays() {
    this.showWeekdays = !this.showWeekdays;
    this.cdr.detectChanges();

    setTimeout(() => {
      this.resizeMap();
    }, 100);
  }

  toggleWeekdaySelection(place: Place) {
    if (place.showWeekdays !== undefined) {
      place.showWeekdays = !place.showWeekdays;
    } else {
      place.showWeekdays = true; // default value if it was never set
    }
    this.cdr.detectChanges();
  }

  getClosingTime(place: Place): string {
    const today = new Date().getDay();

    const todayPeriod = place?.current_opening_hours?.periods?.find(
      (period: Period) => period.close?.day === today
    );

    if (todayPeriod?.close) {
      const time = `${todayPeriod.close.time}`;
      const closingTime = this.convertToStandardTime(time);
      return closingTime;
    }

    return '';
  }

  getOpeningTime(place: Place): string {
    const today = new Date().getDay();
    const tomorrow = (today + 1) % 7;

    const tomorrowPeriod = place.current_opening_hours?.periods?.find(
      (period: Period) => period.open.day === tomorrow
    );

    return tomorrowPeriod
      ? this.convertToStandardTime(tomorrowPeriod.open.time)
      : '';
  }

  resizeMap() {
    if (this.map) {
      google.maps.event.trigger(this.map, 'resize');
      if (
        this.selectedPartner &&
        this.selectedPartner.geometry &&
        this.selectedPartner.geometry.location
      ) {
        const location = new google.maps.LatLng(
          this.selectedPartner.geometry.location.lat(),
          this.selectedPartner.geometry.location.lng()
        );
        this.map.setCenter(location);
      }
    }
  }

  goBackToListView() {
    this.backToList.emit();
    this.cdr.detectChanges();
    //window.location.reload();
  }

  getStarsArray(): number[] {
    const rating = +this.selectedPartner.rating;
    if (!isNaN(rating) && isFinite(rating)) {
      return new Array(Math.round(rating)).fill(1);
    } else {
      return [];
    }
  }

  addPartnersClicked() {
    this.addPartners.emit(this.selectedPartner);
  }

  isRatingValid(): boolean {
    return (
      typeof this.selectedPartner.rating === 'number' &&
      !isNaN(this.selectedPartner.rating)
    );
  }

  isPartnerFavorited(): boolean {
    const storedGooglePlacesIdsString = localStorage.getItem('googlePlacesIds');
    let storedGooglePlacesIds: string[] = [];
    if (storedGooglePlacesIdsString) {
      storedGooglePlacesIds = JSON.parse(storedGooglePlacesIdsString);
    }
    this.cdr.markForCheck();  // Trigger change detection
    return storedGooglePlacesIds.includes(this.selectedPartner.place_id);
  }

  getClosingTimeForToday(): string | null {
    const today = new Date().getDay();

    // Using optional chaining to ensure that we're not accessing properties of undefined
    const todayPeriods = this.selectedPartner?.opening_hours?.periods.find(
      (period: any) => period.close.day === today
    );

    if (todayPeriods && todayPeriods.close) {
      const time = `${todayPeriods.close.hours}${
        todayPeriods.close.minutes < 10
          ? '0' + todayPeriods.close.minutes
          : todayPeriods.close.minutes
      }`;
      return this.convertToStandardTime(time);
    }

    return null;
  }

  getOpeningTimeForTomorrow(): string | null {
    if (
      !this.selectedPartner ||
      !this.selectedPartner.opening_hours ||
      !this.selectedPartner.opening_hours.periods
    ) {
      return null;
    }

    const today = new Date().getDay();
    const tomorrow = (today + 1) % 7;
    const tomorrowPeriods = this.selectedPartner.opening_hours.periods.find(
      (period: any) => period.open && period.open.day === tomorrow
    );

    if (
      tomorrowPeriods &&
      tomorrowPeriods.open &&
      typeof tomorrowPeriods.open.hours === 'number' &&
      typeof tomorrowPeriods.open.minutes === 'number'
    ) {
      const hour =
        tomorrowPeriods.open.hours < 10
          ? '0' + tomorrowPeriods.open.hours
          : tomorrowPeriods.open.hours;
      const minutes =
        tomorrowPeriods.open.minutes < 10
          ? '0' + tomorrowPeriods.open.minutes
          : tomorrowPeriods.open.minutes;
      const time = `${hour}:${minutes}`;
      return this.convertToStandardTime(time);
    }

    return null;
  }

  convertToStandardTime(time: string): string {
    const hour = parseInt(time.substring(0, 2));
    const minutes = time.substring(2);

    let formattedTime = '';

    if (hour === 0) {
      formattedTime = `12:${minutes} AM`;
    } else if (hour < 12) {
      formattedTime = `${hour}:${minutes} AM`;
    } else if (hour === 12) {
      formattedTime = `12:${minutes} PM`;
    } else {
      formattedTime = `${hour - 12}:${minutes} PM`;
    }

    // Check if there's an extra colon and remove it if present
    if (formattedTime.includes('::')) {
      formattedTime = formattedTime.replace(/::/, ':');
    }

    return formattedTime;
  }
}

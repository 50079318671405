import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessNameService {
    private businessNameSubject = new BehaviorSubject<string | null>(null);
    private legalNameSubject = new BehaviorSubject<string | null>(null);
    private accountIdSubject = new BehaviorSubject<string | null>(null);
    public shelterBrandingDaysSubject = new BehaviorSubject<number>(0);

    public businessName$ = this.businessNameSubject.asObservable();
    
    set businessName(name: string | null) {
        this.businessNameSubject.next(name);
    }    
    
    get businessName(): string | null {
        return this.businessNameSubject.getValue();
    }
    
    public legalName$ = this.legalNameSubject.asObservable();
    
    set legalName(name: string | null) {
        this.legalNameSubject.next(name);
    }    
    
    get legalName(): string | null {
        return this.legalNameSubject.getValue();
    }

    public shelterBrandingDays$ = this.shelterBrandingDaysSubject.asObservable();

    set shelterBrandingDays(days: number) {
        this.shelterBrandingDaysSubject.next(days);
    }    
    
    get shelterBrandingDays(): number {
        return this.shelterBrandingDaysSubject.getValue();
    }

    set accountId(name: string | null) {
        this.accountIdSubject.next(name);
    }    
    
    get accountId(): string | null {
        return this.accountIdSubject.getValue();
    }
}

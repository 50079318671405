import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  private logoutTimeout: any;

  constructor(private router: Router) {
    this.logoutTimeout = null;
  }

  logoutAfterDelay(delay: number) {
    // Clear any existing timeout to prevent multiple logout calls
    this.clearLogoutTimeout();
    // Set the new logout timeout
    this.logoutTimeout = setTimeout(() => {
      this.clearAllLocalStorage(); // Clear all local storage items

      this.logout();
    }, delay);
  }

  logout() {
    this.clearAllLocalStorage(); // Clear all local storage items

    // Redirect to the desired route
    this.router.navigateByUrl('/');
  }

  logoutBtn() {
    this.clearLogoutTimeout();

    this.clearAllLocalStorage(); // Clear all local storage items

    this.router.navigateByUrl('/');
  }

  private clearLogoutTimeout() {
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
      this.logoutTimeout = null;
    }
  }

  private clearAllLocalStorage() {
    localStorage.clear(); // Clears all items in local storage
  }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessNameService } from 'projects/petszel-owner/src/app/service/businessName.service';

@Component({
  selector: 'lib-petszel-powered',
  templateUrl: './petszel-powered.component.html',
  styleUrls: ['./petszel-powered.component.css']
})
export class PetszelPoweredComponent {
  @Input() shelterLogoSrc!: any;
  @Input() ShelterAlt!: string;
  @Input() size: 'small' | 'large' = 'small';

  constructor(private router: Router){}

  ngOnInit(){
  }

  navigateToShelter() {
    // Opens the URL in a new tab
    this.router.navigate(['/organization']);
  }
  

  get petszelLogoSrc(): string {
    return this.size === 'small' ? '../../assets/images/petszelPoweredByLogo.png' : '../../assets/images/petszelPoweredByLogo.png';
  }  
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedLibraryService } from '../../../../petszel-shared/src/lib/services/shared.config';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LocalPartnerSearchService {
  public token = localStorage.getItem('token');
  public headers: HttpHeaders;

  constructor(private http: HttpClient,
    private sharedLibraryService: SharedLibraryService) {
    if (this.token) {
      this.headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    } else {
      this.headers = new HttpHeaders();
    }
   }

   getPartnerCategories(){
    const apiUrl = this.sharedLibraryService.getSharedLibraryConfig().apiUrl;
    return this.http.get(apiUrl + 'PetszelConstants/PartnerCategories', { headers: this.headers });
   }

   getShelterPartners(accountId: string) {
    const apiUrl = this.sharedLibraryService.getSharedLibraryConfig().apiUrl;
    return this.http.get(apiUrl + `Staff/Shelters/${accountId}/Partners`, { headers: this.headers });
  }

   getShelters() {
    const apiUrl = this.sharedLibraryService.getSharedLibraryConfig().apiUrl;
    return this.http.get(apiUrl + 'Staff/Shelters/Onboarded?page=1&count=500', { headers: this.headers });
   }

   getShelter(accountId: string){
    const apiUrl = this.sharedLibraryService.getSharedLibraryConfig().apiUrl;
    return this.http.get(apiUrl + `Staff/Shelters/${accountId}`, { headers: this.headers });
   }

   updateShelterPartner(accountId: number, partner: any): Observable<any> {
    const apiUrl = this.sharedLibraryService.getSharedLibraryConfig().apiUrl;
    return this.http.put(apiUrl + `Staff/Shelters/${accountId}/Partners`, partner, { headers: this.headers });
  }  

   postShelterPartners(accountId: string, partner: any){
    const apiUrl = this.sharedLibraryService.getSharedLibraryConfig().apiUrl;
    return this.http.post(apiUrl + `Staff/Shelters/${accountId}/Partners`, partner, { headers: this.headers });
   }

   deletePartner(accountId: string, partnerId: string){
    const apiUrl = this.sharedLibraryService.getSharedLibraryConfig().apiUrl;
    return this.http.delete(apiUrl + `Staff/Shelters/${accountId}/Partners?partnerId=${partnerId}`, { headers: this.headers });
   }

   getShelterCategory(){
    const apiUrl = this.sharedLibraryService.getSharedLibraryConfig().apiUrl;
    return this.http.get(apiUrl + `Owner/Shelter/Partners`, { headers: this.headers });
   }

}

<div class="card petszel-partner-search-result-list-item mb-4">
    <div class="card-body">
        <div class="row">
            <div class="col-md-8">
                <h5 class="card-title search-result-list-item-title">{{selectedPartner.name}}</h5>
                <p class="card-text search-result-list-item-address">{{selectedPartner.formatted_address}}</p>
                <p class="card-text search-result-list-item-phone">{{selectedPartner.formatted_phone_number}}</p>
            </div>
        </div>       
        <div class="petszel-partner-search-result-list-item-selected row mt-3">
            <div class="col-md-6">
                <strong>Primary Category</strong>
                <select class="form-select form-select-sm" [(ngModel)]="selectedPartner.selectedCategory" aria-label=".form-select-sm example">
                    <option *ngFor="let category of categories" [value]="category.key">{{ category.value }}</option>
                </select>
            </div>
            <div class="col-md-6">
                <strong>Secondary Category</strong>
                <select class="form-select form-select-sm" [(ngModel)]="selectedPartner.selectedSecondaryCategory" aria-label=".form-select-sm example">
                    <option [value]="null"></option>
                    <option *ngFor="let category of categories" [value]="category.key">{{ category.value }}</option>
                </select>
            </div>
        </div>
    </div>
</div>
<hr>
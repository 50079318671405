import { AbstractControl, ValidatorFn } from '@angular/forms';

export function domainValidatorForCredentials(
  domain: string,
  allowedCredentials: string[]
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const username: string = control.value;
    const credentialTypeControl = control.parent?.get('credentialType');
    const credentialType = credentialTypeControl?.value;
    const isAllowedCredential = allowedCredentials.includes(credentialType || '');

    if (!isAllowedCredential && !username.endsWith(`@${domain}`)) {
      return { badcredentials: true };
    }
    return null;
  };
}


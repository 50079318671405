<!-- footer nav -->
<div id="navBottomRow" *ngIf="isLoggedIn"
  >
  <div class="col-sm-12 p-0">
    <nav class="nav">
      <ng-container *ngFor="let item of navItems">
        <a
        *ngIf="!item.disabled"
        [routerLink]="item.routerLink && !item.action ? item.routerLink : null"
        routerLinkActive="active"
        [queryParams]="item.queryParams"
        [routerLinkActiveOptions]="{ exact: false }"
        class="nav__link"
        (click)="performItemClick(item, $event)"
          >
          <img
            [src]="item.icon"
            alt="{{ item.label }} Icon"
            class="nav__icon nav__icon--default"
          />
          <img
            [src]="item.iconActive"
            alt="{{ item.label }} Icon"
            class="nav__icon nav__icon--active"
          />
          <span class="nav__text">{{ item.label }}</span>
        </a>
        <a *ngIf="item.disabled" class="nav__link disabled">
          <img
            [src]="item.icon"
            alt="{{ item.label }} Icon"
            class="nav__icon nav__icon--default"
          />
          <img
            [src]="item.iconActive"
            alt="{{ item.label }} Icon"
            class="nav__icon nav__icon--active"
          />
          <span class="nav__text">{{ item.label }}</span>
        </a>
      </ng-container>
    </nav>
  </div>
</div>
<div class="row d-flex justify-content-center">
  <div class="col-md-12 d-flex justify-content-center">
    <div class="vl d-flex align-items-center">
      <a (click)="navigateToShelter()"> <!-- Use click event -->
        <img
            [src]="shelterLogoSrc"
            [alt]="ShelterAlt" 
            [class.small-size]="size === 'small'"
            [class.large-size]="size === 'large'"
            class="brandLogo pe-1"
          />
      </a>
    </div>
    <div class="d-flex flex-column petszel-column petszel-branding ms-1"
         [class.small-size]="size === 'small'"
         [class.large-size]="size === 'large'">
      <img
        [src]="petszelLogoSrc"
        alt="Petszel Logo"
        [class.small-size]="size === 'small'"
        [class.large-size]="size === 'large'"
        class="petszelLogo pe-4"
      />    
    </div>
  </div>
</div>

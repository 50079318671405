import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ReactiveFormsModule } from "@angular/forms";
import { PetszelSharedModule } from "../../../petszel-shared/src/lib/petszel-shared.module";
import { NgbDateAdapter, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { CustomAdapter, CustomDateParserFormatter } from "./date-picker-adapter/date-picker.adapter";
import { setLicenseKey } from "survey-core";
import { AuthInterceptor } from './service/auth-interceptor';
import { TermsOfServiceModalComponent } from './components/terms-of-service-modal/terms-of-service-modal.component';

setLicenseKey(
  "YzNkNDg3NTQtOGQ0Ny00Y2U1LWFkNzgtZTgzOTgzMmY5NTZjOzE9MjAyNS0wMy0xNCwyPTIwMjUtMDMtMTQsND0yMDI1LTAzLTE0"
);

@NgModule({
  declarations: [
    AppComponent,
    ChangePasswordComponent,
    TermsOfServiceModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    CommonModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG, // set log level
      serverLogLevel: NgxLoggerLevel.ERROR, // set server log level
      disableConsoleLogging: false //enable/disable console logging
    }),
    ReactiveFormsModule,
    PetszelSharedModule
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }




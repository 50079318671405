<!-- Show the small map on small screens (hidden on medium and larger screens) -->
<div *ngIf="isSmallScreen">
  <google-map
  #smallMap
  class="petszel-map small-map"
  height="200px"
  width="100%"
  [center]="center"
  [zoom]="zoom"
  [options]="mapOptions"> <!-- Bind the options here -->
    <map-marker #markerElem="mapMarker" *ngFor="let marker of markers" [position]="marker.position" [title]="marker.title" [options]="marker.options"></map-marker>
</google-map>
</div>

<!-- Show the large map on medium and larger screens (hidden on small screens) -->
<div *ngIf="!isSmallScreen">
  <google-map
  #largeMap
  class="petszel-map large-map"
  height="800px"
  width="100%"
  [center]="center"
  [zoom]="zoom"
  [options]="mapOptions"> <!-- Bind the options here -->
    <map-marker #markerElem="mapMarker" *ngFor="let marker of markers" [position]="marker.position" [title]="marker.title" [options]="marker.options"></map-marker>
</google-map>
</div>

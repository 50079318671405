import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms'

@Component({
  selector: 'lib-petszel-partner-search-selected-item',
  templateUrl: './petszel-partner-search-selected-item.component.html',
  styleUrls: ['./petszel-partner-search-selected-item.component.css']
})
export class PetszelPartnerSearchSelectedItemComponent {
  @Input() selectedPartner: any;
  @Input() categories: any;
  @Input() owner!: false;

}

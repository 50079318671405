import { Inject, Injectable } from '@angular/core';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthGuardService } from './authentication-guard.service';
import { appConfig } from '../../owner.config';
import { SHARED_LIBRARY_CONFIG } from 'projects/petszel-shared/src/public-api';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { time } from '@rxweb/reactive-form-validators';

declare let gtag: Function;

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private environmentType!: string;
  private ownerId!: string;
  private ownerIdSubject = new BehaviorSubject<string | null>(null);
  private pageEnterTime: number | null = null;

  constructor(
    @Inject(SHARED_LIBRARY_CONFIG) private config: any,
    private router: Router,
    private authService: AuthGuardService,
    private logger: NGXLogger
  ) {
    // Determine the GA Tracking ID based on the environment
    this.initializeGoogleAnalytics(config.gaTrackingId); // Use the dynamically determined GA Tracking ID
    this.environmentType = this.config.environmentType;

    this.router.events
      .pipe(
        filter(
          (event: RouterEvent): event is NavigationEnd =>
            event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.logger.log('Page navigation ended. Previous page:', event.url); // Log the URL of the previous page
        this.trackPageTime(); // Call when the page changes
        this.pageEnterTime = performance.now(); // Set the new entry time
        this.logger.log('New page entry time set:', this.pageEnterTime); // Log the new entry time
      });
    this.ownerIdSubject.subscribe((ownerId) => {
      if (ownerId) {
        this.setGAUserId(ownerId);

        const trafficType =
          this.environmentType === 'local' ? 'internal' : 'external';
        this.logger.log(`Traffic type: ${trafficType}`);
        gtag('config', config.gaTrackingId, {
          page_path: this.router.url,
          user_id: ownerId,
          traffic_type: trafficType,
        });

        if (this.environmentType === 'local') {
          gtag('event', 'internal_traffic', {
            internal_traffic: 'yes',
          });
        }
      }
    });
  }

  private initializeGoogleAnalytics(trackingId: string) {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', trackingId);
      gtag('set', { user_id: this.getOwnerId() });

    };
  }

  trackPageTime() {
    if (this.pageEnterTime && this.getOwnerId()) {
      const timeSpent = (performance.now() - this.pageEnterTime) / 1000;
      this.trackEvent('time_spent', {
        event_category: 'Engagement',
        event_label: 'Time Spent',
        value: timeSpent,
        page_path: this.router.url,
        user_id: this.getOwnerId(),
      });
      this.pageEnterTime = null;
    } else {
    }
  }

  setOwnerId(id: string) {
    this.ownerId = id;
    this.ownerIdSubject.next(id);
  }

  getOwnerId(): string {
    return this.ownerId;
  }

  private setGAUserId(ownerId: string) {
    gtag('set', 'user_properties',
      { user_id: ownerId });
    }

  setUserProperties(properties: { [key: string]: any }) {
    gtag('set', { 'user_id': this.ownerId });
    gtag('set', 'user_properties', properties);
  }

  trackEvent(eventName: string, params: { [key: string]: any }) {
    const ownerId = this.getOwnerId();
    if (this.ownerId) {

      const userProperties: { [key: string]: any } = {};
      if (eventName === 'animal_type_interaction') {
        userProperties['animal_type'] = params['event_label'];
      } else if (eventName === 'business_details') {
        userProperties['business_category'] = params['event_category'];
        userProperties['account_id'] = params['account_id'];
        userProperties['legal_name'] = params['legal_name'];
      } else if (eventName === 'set_user_gender') {
        userProperties['gender'] = params['user_gender'];
      } else if (eventName === 'page_view') {
        userProperties['pagePath'] = params['page_path'];
      } else if (eventName === 'article_view') {
        userProperties['articleTitle'] = params['event_label'];
        userProperties['articleId'] = params['article_id'];
      } else if (eventName === 'search') {
        userProperties['searchQuery'] = params['event_label'];
      } else if (eventName === 'button_click') {
        userProperties['button_label'] = params['event_label'];
      }

      this.setUserProperties(userProperties);

      gtag('event', eventName, {
        ...params,
        'user_id': this.ownerId,
      });
    } else {
    }
  }

  buildUserProperties(eventName: string, params: { [key: string]: any }): { [key: string]: any } | null {
    const userProperties: { [key: string]: any } = {};

    // Depending on the event, set different user properties
    switch (eventName) {
      case 'animal_type_interaction':
        userProperties['animal_type'] = params['event_label'];
        break;
      case 'business_details':
        userProperties['business_category'] = params['event_category'];
        userProperties['account_id'] = params['account_id'];
        userProperties['legal_name'] = params['legal_name'];
        break;
      case 'set_user_gender':
        userProperties['gender'] = params['user_gender'];
        break;
      case 'page_view':
        userProperties['pagePath'] = params['page_path'];
        break;
      case 'article_view':
        userProperties['articleTitle'] = params['event_label'];
        userProperties['articleId'] = params['article_id'];
        break;
      case 'search':
        userProperties['searchQuery'] = params['event_label'];
        break;
      // Add cases for other event types as needed
      default:
        // If no user properties are to be set for this event, return null
        return null;
    }

    // Return the user properties object if properties are set
    return userProperties;
  }


  trackAnimalType(animalType: string, ownerId: string) {
    this.trackEvent('animal_type', {
      event_category: 'Animal Type',
      event_label: animalType,
      animal_type: animalType,
      owner_id: ownerId
    });
  }

  trackSurveySubmission(type: 'start' | 'end', surveyId: string, ownerId: string, petId: string) {
    const event: string = type === 'start' ? 'survey_start' : 'survey_complete';
    this.trackEvent(event, {
      event_category: 'Survey',
      event_label: type === 'start' ? 'Survey Start' : 'Survey Complete',
      survey_id: surveyId,
      owner_id: ownerId,
      pet_id: petId
    });
  }

  trackBusinessDetails(accountId: string, legalName: string) {
    this.trackEvent('business_details', {
      event_category: 'Business',
      event_label: 'Business Details',
      account_id: accountId,
      legal_name: legalName,
    });
  }

  trackUserGender(gender: string) {
    this.trackEvent('set_user_gender', {
      event_category: 'User Info',
      event_label: 'Gender',
      user_gender: gender,
    });
  }

  trackPageView() {
    const ownerId = this.getOwnerId();
    const pagePath = this.router.url; // Ensure this is the correct way to get the current URL path in your application

    if (ownerId) {
      this.trackEvent('page_view', {
        page_location: window.location.href,
        page_path: pagePath,
        page_title: document.title,
        user_id: ownerId,
      });
    }
  }

  trackArticleView(articleId: string, articleTitle: string) {
    this.logger.log(
      'trackArticleView called with articleId:',
      articleId,
      'and articleTitle:',
      articleTitle
    );

    const ownerId = this.getOwnerId();
    const pagePath = this.router.url; // Get the current router path
    this.logger.log(pagePath);

    if (ownerId) {
      this.trackEvent('article_view', {
        event_category: 'Article',
        event_label: articleTitle,
        article_id: articleId,
        user_id: ownerId,
        page_path: pagePath,
      });
    } else {
      this.logger.error(`User ID not set for article view event`);
    }
  }

  async getCurrentPagePath(): Promise<string> {
    return new Promise<string>((resolve) => {
      this.router.events
        .pipe(
          filter(
            (event: RouterEvent): event is NavigationEnd =>
              event instanceof NavigationEnd
          )
        )
        .subscribe((event: NavigationEnd) => {
          resolve(event.urlAfterRedirects);
        });
    });
  }

  trackUserSearch(searchQuery: string) {
    const ownerId = this.getOwnerId();
    if (ownerId) {
      this.trackEvent('search', {
        event_category: 'User Search',
        event_label: searchQuery,
        user_id: ownerId,
      });
    } else {
      this.logger.error(`User ID not set for search event`);
    }
  }

  trackFormSubmission(formName: string, action: string) {
    const ownerId = this.getOwnerId();

    // Prepare the event parameters
    const eventParams = {
      event_category: 'Providers Form',
      event_label: formName,
      user_id: ownerId
    };

    // Check if the ownerId is available
    if (ownerId) {
      // Log the event for debugging
      this.logger.log(`Tracking Event: ${action}`, eventParams);

      // Send the event with the user_id to Google Analytics
      gtag('event', action, eventParams);
    } else {
      // Log an error for missing user ID
      this.logger.error(`User ID not set for form submission event: ${formName}`);
    }
  }
}

import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private partnersDataSource = new BehaviorSubject<any[]>([]);
  partnersData$ = this.partnersDataSource.asObservable();
  private apiKey: string | null = null;
  constructor(private logger: NGXLogger) { }

  setApiKey(key: string): void {
    this.apiKey = key;
  }

  getApiKey(): string | null {
    this.logger.log(this.apiKey)

    return this.apiKey;
  }

  setPartnersData(data: any[]) {
    this.partnersDataSource.next(data);
  }

  getPartnersDataObservable(): Observable<any[]> {
    return this.partnersDataSource.asObservable();
}

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent {

  /**
   * Determines if loader position is relative.
   * Default: true
   *
   * @type {boolean}
   */
  @Input() isRelative: boolean = true;

}

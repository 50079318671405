<div
  class="row d-flex justify-content-center align-items-center min-vh-100 reset"
>
  <form
    class="col-xxl-3 col-sm-5 col m-5 box-border"
    [formGroup]="formResetEmail"
    (ngSubmit)="onSubmit()"
  >
    <div class="row d-flex flex-column align-items-center text-center padding-petszel-logo-reset">
      <div class="col-sm-6 col">
        <img class="logo" src="../../assets/images/petszelLogo.png" />
      </div>
    </div>
    <div class="row d-flex flex-column align-items-center text-center">
      <div class="col-sm-8 col">
        <h3 class="font" *ngIf="!resetIdExists">Reset Password</h3>
        <h3 class="font" *ngIf="resetIdExists">Create Password</h3>
      </div>
    </div>    
    <div class="row d-flex justify-content-center">
        <div class="col-sm-12 col d-flex justify-content-center">
    <div class="form-group">
      <label for="username" class="reset-email"
        >Enter account's verified email address and we will send you a password
        reset link.</label
      >

      <input
        formControlName="username"
        type="username"
        class="form-control"
        id="username"
        placeholder="JohnDoe@example.com"
        required
      />
    </div>
</div>
</div>
    <div class="row">
      <div class="col-sm-12 col d-flex justify-content-center mt-5">
        <input
          class="btn btn-primary d-flex justify-content-center"
          type="submit"
          value="Reset Password"
          *ngIf="!resetIdExists"
        />
        <input
          class="btn btn-primary d-flex justify-content-center"
          type="submit"
          value="Create Password"
          *ngIf="resetIdExists"
        />
      </div>
    </div>
  </form>
</div>

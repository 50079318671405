import { Component, TemplateRef } from '@angular/core';
import { ToastsService } from "../services/toasts.service";

@Component({
  selector: 'lib-toasts-container',
  templateUrl: './toasts-container.component.html',
  styleUrls: ['./toasts-container.component.css'],
  host: { class: 'toast-container position-absolute start-50 translate-middle' }
})
export class ToastsContainerComponent {

  constructor(public toastService: ToastsService) {}

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from '../../owner.config';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { ShelterPartner } from '../models/shelterPartner.interface';

@Injectable({
  providedIn: 'root',
})
export class MyProvidersService {
  public token = localStorage.getItem('token');
  public headers: HttpHeaders;

  constructor(private http: HttpClient, private logger: NGXLogger) {
    if (this.token) {
      this.headers = new HttpHeaders().set(
        'Authorization',
        `Bearer ${this.token}`
      );
    } else {
      this.headers = new HttpHeaders();
    }
  }

  getPartners(petszelAnimalId: string): Observable<any> {
    localStorage.setItem('petszelAnimalId', petszelAnimalId);
    const url = `${appConfig.apiUrl}Owner/Animals/${petszelAnimalId}/Partners`;
    return this.http.get(url, { headers: this.headers });
  }

  putPartner(petszelAnimalId: string, partnerData: any) {
    if (!partnerData.address_components && !partnerData.formatted_address) {
      this.logger.error('Address data is missing for partner:', partnerData);
      // Handle the error (e.g., return, set default address, etc.)
      return;
    }
    // Create the payload for the PUT request to match the structure of the POST request
    const processedAddress = this.processAddressRecommended(
      partnerData.address_components,
      partnerData.formatted_address
    );

    const payload = {
      id: petszelAnimalId,
      Name: partnerData.name,
      Phone: {
        PhoneNumber: partnerData.formatted_phone_number,
        isMobile: true,
        okayToSendSMS: true,
        description: partnerData.Phone?.description,
      },
      Address: {
        streetLine1: processedAddress.StreetLine1,
        streetLine2: processedAddress.StreetLine2,
        city: processedAddress.City,
        stateProvince: processedAddress.StateProvince,
        zipCode: processedAddress.ZipCode,
        country: processedAddress.Country,
      },
      googlePlacesId: partnerData.googlePlacesId,
      website: partnerData.website,
    };

    const url = `${appConfig.apiUrl}Owner/Animals/${petszelAnimalId}/Partners`;
    this.http.put(url, payload, { headers: this.headers }).subscribe(
      (response) => {
        this.logger.log('PUT request successful:', response);
      },
      (error) => {
        this.logger.error('Error while making PUT request:', error);
      }
    );
  }

  getShelterPartners(): Observable<ShelterPartner[]> {
    const url = `${appConfig.apiUrl}Owner/Shelter/Partners`;
    return this.http.get<ShelterPartner[]>(url, { headers: this.headers });
  }

  deletePartners(petszelAnimalId: string, partnerId: string): Observable<any> {
    const url = `${appConfig.apiUrl}Owner/Animals/${petszelAnimalId}/Partners?partnerId=${partnerId}`;
    return this.http.delete(url, { headers: this.headers });
  }

  postRecommended(selectedPetId: string, partnerData: any) {
    const processedAddress = this.processAddressRecommended(
      partnerData.address_components,
      partnerData.formatted_address
    );

    const payload = {
      petId: selectedPetId,
      Name: partnerData.name,
      Phone: {
        PhoneNumber: partnerData.formatted_phone_number,
      },
      Address: {
        streetLine1: processedAddress.StreetLine1,
        streetLine2: processedAddress.StreetLine2,
        city: processedAddress.City,
        stateProvince: processedAddress.StateProvince,
        zipCode: processedAddress.ZipCode,
        country: processedAddress.Country,
      },
      googlePlacesId: partnerData.googlePlacesId,
      website: partnerData.website,
    };

    const url = `${appConfig.apiUrl}Owner/Animals/${selectedPetId}/Partners`;
    this.http.post(url, payload, { headers: this.headers }).subscribe(
      (response) => {
        this.logger.log('POST request successful:', response);
      },
      (error) => {
        this.logger.error('Error while making POST request:', error);
      }
    );
  }

  processAddressRecommended(
    addressComponents?: any[],
    formattedAddress?: string
  ): any {
    if (!addressComponents && !formattedAddress) {
      this.logger.error(
        'Both addressComponents and formattedAddress are missing in processAddressRecommended'
      );
      return {};
    }

    let processedAddress: any = {};

    if (addressComponents) {
      // Existing processing logic...
      addressComponents.forEach((component) => {
        if (component.types.includes('locality')) {
          processedAddress.City = component.long_name;
        } else if (component.types.includes('country')) {
          processedAddress.Country = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          processedAddress.StateProvince = component.long_name;
        } else if (component.types.includes('route')) {
          processedAddress.StreetLine1 =
            (processedAddress.StreetLine1 || '') + ' ' + component.long_name;
        } else if (component.types.includes('street_number')) {
          processedAddress.StreetLine1 =
            component.long_name + (processedAddress.StreetLine1 || '');
        } else if (component.types.includes('postal_code')) {
          processedAddress.ZipCode = component.long_name;
        }
      });
    }

    if (formattedAddress) {
      const parts = formattedAddress.split(',').map((part) => part.trim());
      if (parts.length >= 3) {
        processedAddress.StreetLine1 = parts[0];
        processedAddress.City = parts[1];
        const stateZip = parts[2].split(' ').map((part) => part.trim());
        if (stateZip.length == 2) {
          processedAddress.StateProvince = stateZip[0];
          processedAddress.ZipCode = stateZip[1];
        }
        processedAddress.Country = parts[3] || ''; // If there's a 4th part
      }
    }

    return processedAddress;
  }

  postPartners(selectedPetId: string, partnerData: any) {
    const processedAddress = this.processAddressComponents(
      partnerData.address_components
    );

    const payload = {
      petId: selectedPetId,
      Name: partnerData.name,
      Phone: {
        PhoneNumber: partnerData.formatted_phone_number,
      },
      Address: processedAddress,
      googlePlacesId: partnerData.place_id,
      website: partnerData.website,
    };

    const url = `${appConfig.apiUrl}Owner/Animals/${selectedPetId}/Partners`;
    this.http.post(url, payload, { headers: this.headers }).subscribe(
      (response) => {
        this.logger.log('POST request successful:', response);
      },
      (error) => {
        this.logger.error('Error while making POST request:', error);
      }
    );
  }

  processAddressComponents(components: any[] = []): any {
    let processedAddress = {
      City: '',
      Country: '',
      StateProvince: '',
      StreetLine1: '',
      ZipCode: '',
    };

    components.forEach((component: any) => {
      if (component.types.includes('locality')) {
        processedAddress.City = component.long_name;
      } else if (component.types.includes('country')) {
        processedAddress.Country = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        processedAddress.StateProvince = component.short_name;
      } else if (
        component.types.includes('street_number') ||
        component.types.includes('route')
      ) {
        processedAddress.StreetLine1 += component.long_name + ' ';
      } else if (component.types.includes('postal_code')) {
        processedAddress.ZipCode = component.long_name;
      }
    });

    return processedAddress;
  }
}

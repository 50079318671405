<!-- hamburger nav -->
<div id="navRow" class="row">
  <div class="col-sm-12 p-0">
    <nav
      class="navbar navbar-expand-lg d-flex justify-content-start d-flex d-md-none d-lg-none d-xl-none"
    >
    <button id="navbarToggler" class="navbar-toggler" type="button" (click)="toggleNavbar()" [ngClass]="{'collapsed': isNavbarCollapsed}">
      <span class="toggler-icon top-bar" [ngClass]="{'rotated': !isNavbarCollapsed}"></span>
      <span class="toggler-icon middle-bar" [ngClass]="{'hidden': !isNavbarCollapsed}"></span>
      <span class="toggler-icon bottom-bar" [ngClass]="{'rotated': !isNavbarCollapsed}"></span>
    </button>

      <div class="row d-lg-none d-xl-none d-xxl-none d-md-none branding-row m-auto">
        <div class="col-12 d-flex justify-content-center align-items-center branding">
          <ng-container *ngIf="shelterLogoSrc && (this.daysSinceAdoption < this.brandingDays)">
            <lib-petszel-powered
              ShelterAlt="Organization Logo"
              [shelterLogoSrc]="shelterLogoSrc || '../../../assets/images/loading.gif'"
              size="small"
              class="d-flex branding justify-content-center"
            ></lib-petszel-powered>
          </ng-container>
          <ng-container *ngIf="!shelterLogoSrc && (this.daysSinceAdoption >= this.brandingDays)">
            <lib-petszel-powered-nobrand
              ShelterAlt="Organization Logo"
              [shelterLogoSrc]="shelterLogoSrc || '../../../assets/images/loading.gif'"
              size="small"
              class="d-flex branding justify-content-center"
            ></lib-petszel-powered-nobrand>
          </ng-container>
          <ng-container *ngIf="!shelterLogoSrc && !isApplication1">
            <div class="row d-flex flex-row justify-content-center m-auto">
              <div class="col-8 logo-container p-0">
                <img
                  [src]="logo?.logoUrl"
                  alt="Shelter Logo"
                  class="img-fluid"
                  style="max-width: 100%; height: auto; margin: auto"
                />
              </div>
              <div class="col-4 petszel-container p-0">
                <img
                  src="../../../assets/images/petszelPoweredByLogo.png"
                  alt="Petszel Logo"
                  class="img-fluid"
                  style="max-width: 100%; height: auto; margin: auto"
                />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div #navbarNav id="navbar" class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <div *ngIf="isLoggedIn">
            <div *ngFor="let item of navItems">
              <li class="nav-item" *ngIf="item.label !== 'Emergency'">
                <a class="nav-link d-flex justify-content-between align-items-center hamburger-text py-1"
                   [routerLink]="item.routerLink && !item.action ? item.routerLink : null"
                   routerLinkActive="active"
                   [queryParams]="item.queryParams"
                   [ngClass]="{ disabled: item.disabled }"
                   [attr.disabled]="item.disabled ? true : null"
                   >
                   {{ item.label }}

                   <i *ngIf="item.subItems"
                      class="bi bi-chevron-down"
                      [ngClass]="{ 'rotate-icon': item.isExpanded }"
                      (click)="toggleSubMenu(item, $event)"
                   ></i>
                </a>
                <!-- Submenu handling remains unchanged -->
                <ng-container *ngIf="item.subItems">
                  <ul class="nav nav-pills flex-column" *ngIf="item.isExpanded">
                    <li class="nav-item ps-2" *ngFor="let subItem of item.subItems">
                      <a class="nav-link ml-4 hamburger-text py-1"
                         [routerLink]="subItem.routerLink"
                         routerLinkActive="active"
                         [queryParams]="subItem.queryParams"
                         [ngClass]="{ disabled: subItem.disabled }"
                         [attr.disabled]="subItem.disabled ? true : null"
                       >
                        {{ subItem.label }}
                      </a>
                    </li>
                  </ul>
                </ng-container>
              </li>
            </div>

          </div>
          <div *ngIf="isLoggedIn && navItems2">

          <hr />
          <div *ngFor="let item of navItems2">
            <li class="nav-item">
              <a
                class="nav-link hamburger-text py-1"
                [routerLink]="item.routerLink ? item.routerLink : null"
                routerLinkActive="active"
                href="/owner-dashboard"
                [ngClass]="{ disabled: item.disabled }"
                [attr.disabled]="item.disabled ? true : null"
              >
                {{ item.label }}
              </a>
            </li>
            </div>
        </div>
        <ng-container *ngIf="navItems3">
          <hr />
          <div *ngFor="let item of navItems3">
            <div *ngIf="isLoggedIn || !item.requiresLogin">
              <li class="nav-item">
                <a
                  class="nav-link hamburger-text py-1"
                  [href]="item.href"
                  [attr.target]="item.newTab ? '_blank' : ''"
                  rel="noopener noreferrer"
                  [ngClass]="{ disabled: item.disabled }"
                  [attr.disabled]="item.disabled ? true : null"
                >
                  {{ item.label }}
                </a>
              </li>
            </div>
          </div>
        </ng-container>        
          <li class="nav-item" *ngIf="checkIsLoggedIn()">
            <a class="nav-link hamburger-text py-1" href="/" (click)="logoutBtn()"
              >Logout</a
            >
          </li>
          <li class="nav-item" *ngIf="!checkIsLoggedIn()">
            <a class="nav-link hamburger-text" href="/"
              >Login</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>

<!-- sidebar nav -->
<div class="row flex-nowrap d-none d-sm-block d-sm-none d-md-block sidebar">
  <div class="sidebar-height d-flex flex-column">
    <div class="side-nav p-2 flex-grow-1">
      <div class="row d-flex">
        <ng-container *ngIf="shelterLogoSrc">
          <ng-container *ngIf="shelterLogoSrc && this.daysSinceAdoption < this.brandingDays">
            <lib-petszel-powered
              ShelterAlt="Organization Logo"
              [shelterLogoSrc]="shelterLogoSrc || '../../../assets/images/loading.gif'"
              size="small"
              class="d-flex justify-content-center"
            ></lib-petszel-powered>
          </ng-container>
          <ng-container *ngIf="shelterLogoSrc && this.daysSinceAdoption >= this.brandingDays">
            <lib-petszel-powered-nobrand
              ShelterAlt="Organization Logo"
              [shelterLogoSrc]="shelterLogoSrc || '../../../assets/images/loading.gif'"
              size="small"
              class="d-flex justify-content-center"
            ></lib-petszel-powered-nobrand>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!shelterLogoSrc && !isApplication1">
          <div class="row d-flex flex-row justify-content-center m-auto">
            <div class="col-8 logo-container p-0">
              <img
                [src]="logo?.logoUrl"
                alt="Organization Logo"
                class="img-fluid"
                style="max-width: 100%; height: auto; margin: auto"
              />
            </div>
            <div class="col-4 petszel-container p-0">
              <img
                src="../../../assets/images/petszelPoweredByLogo.png"
                alt="Petszel Logo"
                class="img-fluid"
                style="max-width: 100%; height: auto; margin: auto"
              />
            </div>
          </div>
        </ng-container> 
        <ng-container *ngIf="!shelterLogoSrc && isApplication1">
          <img
                src="../../assets/images/petszelLogoSm.png"
                alt="Organization Logo"
                class="img-fluid"
                style="width: 125px; margin: auto"
              />
        </ng-container>       
      </div>
      <ul class="nav nav-pills flex-column">
        <hr id="navLine" />
        <!-- For navItems -->
        <div *ngIf="isLoggedIn">
          <ng-container *ngFor="let item of navItems">
            <li class="nav-item my-1">
              <a
                class="nav-link d-flex justify-content-between align-items-center"
                [routerLink]="item.routerLink ? item.routerLink : null"
                routerLinkActive="active"
                [queryParams]="item.queryParams"
                [ngClass]="{ disabled: item.disabled }"
                [attr.disabled]="item.disabled ? true : null"
                (click)="item.action ? performAction(item.action) : toggleSubMenu(item, $event)"
              >
                {{ item.label }}
                <i
                  *ngIf="item.subItems"
                  class="bi bi-chevron-down"
                  [ngClass]="{ 'rotate-icon': item.isExpanded }"
                  (click)="toggleSubMenu(item, $event)"
                ></i>
              </a>
              <ng-container *ngIf="item.subItems">
                <ul class="nav nav-pills flex-column" *ngIf="item.isExpanded">
                  <li class="nav-item ps-2" *ngFor="let subItem of item.subItems">
                    <a
                      class="nav-link ml-4"
                      [routerLink]="subItem.routerLink"
                      routerLinkActive="active"
                      [queryParams]="subItem.queryParams"
                      [ngClass]="{ disabled: subItem.disabled }"
                      [attr.disabled]="subItem.disabled ? true : null"
                    >
                      {{ subItem.label }}
                    </a>
                  </li>
                </ul>
              </ng-container>
            </li>
          </ng-container>
        </div>
        <!-- For navItems2 -->
        <div *ngIf="isLoggedIn && navItems2">
          <hr />
          <ng-container *ngFor="let item of navItems2">
            <li class="nav-item my-1">
              <a
                class="nav-link"
                [routerLink]="item.routerLink ? item.routerLink : null"
                routerLinkActive="active"
                [ngClass]="{ disabled: item.disabled }"
                [attr.disabled]="item.disabled ? true : null"
              >
                {{ item.label }}
              </a>
            </li>
          </ng-container>
        </div>
        <!-- For navItems3 -->
        <div *ngIf="navItems3">
          <hr />
          <ng-container *ngFor="let item of navItems3">
            <div *ngIf="isLoggedIn || !item.requiresLogin">
              <li class="nav-item my-1">
                <a
                  class="nav-link"
                  [href]="item.href"
                  [attr.target]="item.newTab ? '_blank' : ''"
                  rel="noopener noreferrer"
                  [ngClass]="{ disabled: item.disabled }"
                  [attr.disabled]="item.disabled ? true : null"
                >
                  {{ item.label }}
                </a>
              </li>
            </div>
          </ng-container>
        </div>
      </ul>
    </div>
    <div class="login-logout py-5 m-auto">
      <div class="nav-item" *ngIf="checkIsLoggedIn()">
        <button class="hamburger-text nav-logout primaryBtnSm" (click)="logoutBtn()">
          <img src="../../assets/images/login-logout.png" class="me-1 logout"/>
          Logout</button>
      </div>
      <div class="nav-item" *ngIf="!checkIsLoggedIn()">
        <button class="hamburger-text nav-login primaryBtnSm">Login</button>
      </div>
    </div>
  </div>
</div>

